import React, { useCallback, useRef, useState, useEffect } from "react";
import { ShellWrapper } from "../Shell/index.js";
import { t } from "@bookingcom/lingojs-core";
import Results from "./Results.js";
import Tags from "./Tags.js";
import useAutoComplete from "./useAutoComplete.js";
import styles from "./AutoComplete.module.css";
import Chips from "./Chips.js";
import { Anywhere } from "../Anywhere/index.js";
export default function AutoComplete(props) {
    const { segmentIndex, type, onFinish, autoFocusInput, largeInputFontSize, carouselSelected } = props;
    const { addLocation, i18n, isMultiStop, removeLocation, removeLocationByIndex, result, search, selectedLocations, setLocation, state, disableAutocompleteMultiSelect, multiSelectVariant, multiSelectName, onTrackSBExperimentCustomGoal, searchType, isFlyAnywhereExp, isMobile, trackFlyAnywhereWww, trackFlyAnywhereMdot } = useAutoComplete({ segmentIndex, type });
    const [query, setQuery] = useState("");
    const [isFocus, setIsFocus] = useState(false);
    const _inputRef = useRef(null);
    const inputRef = props.overlayInputRef || _inputRef;
    const enableMultiSelect = !isMultiStop && !disableAutocompleteMultiSelect && multiSelectVariant !== 1;
    const handleInpOnChange = useCallback((e) => {
        const { value } = e.target;
        setQuery(value);
        search(value);
    }, [search]);
    const handleInpOnKeyDown = useCallback((e) => {
        if (e.key !== "Backspace" || !!query)
            return;
        if (multiSelectName && !!selectedLocations.length)
            onTrackSBExperimentCustomGoal({ name: multiSelectName, customGoal: 5 });
        removeLocationByIndex(selectedLocations.length - 1);
    }, [query, selectedLocations, removeLocationByIndex]);
    const handleInpOnFocus = useCallback(() => {
        setIsFocus(true);
    }, []);
    const handleInpOnBlur = useCallback(() => {
        setIsFocus(false);
    }, []);
    const handleResultRemove = useCallback((loc) => {
        removeLocation(loc);
    }, [removeLocation]);
    const handleResultSet = useCallback((loc) => {
        setLocation(loc);
    }, [setLocation]);
    const handleChipFocus = useCallback((idx) => {
        const chipsItems = document.querySelectorAll("[data-autocomplete-chip-idx]");
        const nextIdx = Math.min(idx, chipsItems.length - 1);
        if (nextIdx === -1) {
            inputRef.current?.focus();
        }
        else {
            // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
            document.querySelector(`[data-autocomplete-chip-idx='${nextIdx}']`)?.["focus"]?.();
        }
    }, []);
    const handleResultAdd = useCallback((loc) => {
        enableMultiSelect ? addLocation(loc) : setLocation(loc);
        setQuery(""); // to clear the input
        onFinish();
    }, [setLocation, addLocation, onFinish, setQuery, enableMultiSelect]);
    const handleResultAddMulti = useCallback((loc) => {
        addLocation(loc);
        setQuery(""); // to clear the input
    }, [addLocation, setQuery]);
    const handleTagOnClick = useCallback((idx) => {
        if (multiSelectName)
            onTrackSBExperimentCustomGoal({ name: multiSelectName, customGoal: 4 });
        removeLocationByIndex(idx);
        if (multiSelectVariant && multiSelectVariant > 1) {
            setTimeout(() => {
                handleChipFocus(idx);
            }, 0);
        }
    }, [removeLocationByIndex, multiSelectVariant]);
    useEffect(() => {
        if (!autoFocusInput || !inputRef?.current)
            return;
        setTimeout(() => {
            inputRef?.current?.focus();
        }, 100);
    }, [autoFocusInput, inputRef]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.wrapper },
            selectedLocations.length && carouselSelected && multiSelectVariant === 2 ? (React.createElement("div", { style: {
                    width: "100%",
                    maxWidth: "100%",
                    minWidth: "100%",
                    marginBottom: "var(--bui_spacing_2x)"
                } },
                React.createElement(Chips, { i18n: i18n, carousel: !!carouselSelected, selectedLocations: selectedLocations, onClick: handleTagOnClick }))) : undefined,
            !carouselSelected && multiSelectVariant === 2 ? (React.createElement(Chips, { carousel: !!carouselSelected, selectedLocations: selectedLocations, i18n: i18n, onClick: handleTagOnClick })) : undefined,
            React.createElement(ShellWrapper, { border: true, isFocus: isFocus, clickThrough: inputRef },
                React.createElement("div", { className: styles.inputInner },
                    !disableAutocompleteMultiSelect ? (multiSelectVariant === 2 ? undefined : multiSelectVariant === 3 ? (React.createElement(Chips, { selectedLocations: selectedLocations, i18n: i18n, onClick: handleTagOnClick })) : multiSelectVariant !== 1 ? (React.createElement(Tags, { selectedLocations: selectedLocations, i18n: i18n, onClick: handleTagOnClick })) : undefined) : null,
                    React.createElement("input", { className: `${styles.textInput} ${largeInputFontSize ? styles.textInputLarge : ""}`, onBlur: handleInpOnBlur, onChange: handleInpOnChange, onFocus: handleInpOnFocus, onKeyDown: handleInpOnKeyDown, placeholder: i18n.trans(t("flights_multi_select_search_placeholder")), ref: props.overlayInputRef, type: "text", value: query, "data-ui-name": "input_text_autocomplete", role: "combobox", "aria-controls": "flights-searchbox_suggestions", "aria-expanded": !!result.length }))),
            React.createElement(Results, { add: handleResultAdd, addMulti: handleResultAddMulti, error: state.error, i18n: i18n, enableMultiSelect: enableMultiSelect, loading: state.loading, remove: handleResultRemove, result: result, selected: selectedLocations, set: handleResultSet, success: state.success, disableIndent: multiSelectVariant === 1, type: type, searchType: searchType, isFlyAnywhereExp: isFlyAnywhereExp, isMobile: isMobile, trackFlyAnywhereWww: trackFlyAnywhereWww, trackFlyAnywhereMdot: trackFlyAnywhereMdot }),
            type === "to" && isFlyAnywhereExp && !state.loading ? (React.createElement(Anywhere, { add: handleResultAdd, addMulti: handleResultAddMulti, remove: handleResultRemove })) : undefined)));
}
