import Frame from "../../../components/elements/Frame";
import React from "react";
import { Button, Card, Divider, Image, Stack, Text } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { useSelector } from "react-redux";
import { getCaption, getDiscountLink, getHeader, getSubheader } from "store/esim/selectors";
import styles from "./EsimBanner.module.css";
import flights_ace_web_esim_banner from "utils/experiments/ace/flights_ace_web_esim_banner";
import { UIOrderStatus } from "@flights/types";
import { useEsimClickBanner, useTrackEsimShowBanner } from "ancillaries/hooks/Esim/useEsimTrackV2";

interface EsimBannerProps {
  isPostBooking: boolean;
  orderStatus?: UIOrderStatus;
}

const EsimBanner = ({ isPostBooking, orderStatus }: EsimBannerProps) => {
  const i18n = useI18n();
  const header = useSelector(getHeader);
  const subheader = useSelector(getSubheader);
  const caption = useSelector(getCaption);
  const discountLink = useSelector(getDiscountLink);
  const showButton = isPostBooking && !!discountLink && orderStatus === "CONFIRMED";
  const trackEsimClickBanner = useEsimClickBanner();

  const handleButtonClick = () => {
    flights_ace_web_esim_banner.goals.clicked_esim_banner_cta();
    trackEsimClickBanner(showButton);
    window.open(discountLink, "_blank", "noopener noreferrer");
  };

  useTrackEsimShowBanner(showButton);

  return (
    <Card>
      <Stack>
        <Frame mb={1} direction="row" alignItems="start">
          <Frame pr={3} pt={1}>
            <Image
              width="64px"
              height="64px"
              alt="Genius Esim Benefit"
              asset={{
                setName: "illustrations-traveller",
                assetName: "GeniusEsimBenefit"
              }}
              contentMode="fit"
            />
          </Frame>
          <Stack>
            <Text variant="strong_1">{header}</Text>
            <Text variant="body_1">{subheader}</Text>
            <Stack>
              <Text variant="small_1">{caption}</Text>
              {showButton ? (
                <Button variant="secondary" size="medium" onClick={handleButtonClick} className={styles.discountButton}>
                  {i18n.trans(t("flights_genius_esim_cta"))}
                </Button>
              ) : null}
            </Stack>
          </Stack>
        </Frame>
        <Divider />
        <Frame mt={1} direction="row" justifyContent="space-between" alignItems="center">
          <Text variant="small_2" color="neutral_alt">
            {i18n.trans(t("flights_xsell_genius_banner_footer"))}
          </Text>
          <Image
            asset={{
              setName: "images-genius",
              assetName: "GeniusLogo"
            }}
            height="14px"
            width="54px"
            alt="Genius Logo"
            contentMode="fit"
          />
        </Frame>
      </Stack>
    </Card>
  );
};

export default EsimBanner;
