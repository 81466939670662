import React, { useEffect, useMemo } from "react";
import { useI18n } from "@bookingcom/lingojs-react";
import { Divider } from "@bookingcom/bui-react";
import { UIAvailableExtraProducts, UIBrandedFareInfo, UIIncludedProductBySegmentCompressed } from "@flights/types";
import { getFeaturesToRender } from "../CheckoutFareInner/utils/features";
import { t } from "@bookingcom/lingojs-core";
import useTrackInconsistentBaggageFeatures from "hooks/ancillaries/useTrackInconsistentBaggageFeatures";
import FareFeature from "components/elements/FareFeature";
import styles from "./FareFeatures.module.css";
import Frame from "components/elements/Frame";
import FareFeatureHeading from "./FareFeatureHeading";
import flights_apex_web_move_baggage_features_to_server from "utils/experiments/apex/flights_apex_web_move_baggage_features_to_server";
import { getBaggageFeaturesToRender } from "@flights/ancillaries";
import flights_apex_web_sellable_features_v3 from "utils/experiments/apex/flights_apex_web_sellable_features_v3";
import useUserAgent from "hooks/useUserAgent";
import useRouteName from "hooks/useRouteName";

type Props = {
  brandedFareInfo: UIBrandedFareInfo;
  includedProducts?: UIIncludedProductBySegmentCompressed;
  ancillaries?: UIAvailableExtraProducts;
  sellableFeaturesRequiredByAirline?: boolean;
  isPreCheckAndPay?: boolean;
  showDivider?: boolean;
  showHeader?: boolean;
  showDescriptions?: boolean;
  showSubtitles?: boolean;
  compactHeight?: boolean;
  showExcludedFeatures?: boolean;
  areShown?: boolean;
};

const FareFeatures = ({
  brandedFareInfo,
  includedProducts,
  ancillaries,
  sellableFeaturesRequiredByAirline,
  showHeader,
  showDivider,
  isPreCheckAndPay,
  showDescriptions,
  showSubtitles,
  compactHeight = false,
  showExcludedFeatures = true,
  areShown = true
}: Props) => {
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const routeName = useRouteName();

  useTrackInconsistentBaggageFeatures(includedProducts);

  if (!brandedFareInfo) throw new Error("FareSwitcher: missing branded fare info");

  const inSellableFeaturesExperiment = !!flights_apex_web_sellable_features_v3.trackWithDefaultStage();
  const includeSellableFeatures = inSellableFeaturesExperiment || sellableFeaturesRequiredByAirline;
  const includeNonIncludedBaggage = includeSellableFeatures && (isPreCheckAndPay || inSellableFeaturesExperiment);

  const featuresToRender = useMemo(() => {
    return getFeaturesToRender(brandedFareInfo, {
      includeSellableFeatures,
      isPreCheckAndPay: isPreCheckAndPay || inSellableFeaturesExperiment
    });
  }, [brandedFareInfo, includeSellableFeatures, isPreCheckAndPay, inSellableFeaturesExperiment]);

  const baggageFeaturesToRender = useMemo(() => {
    return !!flights_apex_web_move_baggage_features_to_server.variant()
      ? { includedBaggageFeatures: [], excludedBaggageFeatures: [] }
      : getBaggageFeaturesToRender(i18n, includedProducts, ancillaries, { includeNonIncludedBaggage });
  }, [includeNonIncludedBaggage, includedProducts, ancillaries, i18n]);

  const includedAndSellableFeatures = useMemo(() => {
    return [...baggageFeaturesToRender.includedBaggageFeatures, ...featuresToRender.includedFeatures];
  }, [baggageFeaturesToRender.includedBaggageFeatures, featuresToRender.includedFeatures]);

  const excludedFeatures = useMemo(() => {
    return showExcludedFeatures
      ? [...baggageFeaturesToRender.excludedBaggageFeatures, ...featuresToRender.nonIncludedFeatures]
      : [];
  }, [baggageFeaturesToRender.excludedBaggageFeatures, featuresToRender.nonIncludedFeatures, showExcludedFeatures]);

  const hasIncludedOrSellableFeatures = includedAndSellableFeatures.length > 0;
  const hasExcludedFeatures = excludedFeatures.length > 0;

  useEffect(() => {
    if (!areShown) return;
    const options = { includeSellableFeatures: true, isPreCheckAndPay: true };
    const { includedFeatures } = getFeaturesToRender(brandedFareInfo, options);
    const features = [...includedFeatures, ...baggageFeaturesToRender.includedBaggageFeatures];
    flights_apex_web_sellable_features_v3.trackStages({ features, isMobile, routeName });
  }, [brandedFareInfo, baggageFeaturesToRender, areShown, isMobile, routeName]);

  if (!hasIncludedOrSellableFeatures && !hasExcludedFeatures) return null;

  return (
    <>
      {showHeader && hasIncludedOrSellableFeatures && (
        <FareFeatureHeading>{i18n.trans(t("flight_fare_included"))}</FareFeatureHeading>
      )}

      {includedAndSellableFeatures.map((feature) => (
        <FareFeature
          key={feature.name}
          IconSVG={feature.icon}
          content={feature.label}
          description={showDescriptions ? feature.description : undefined}
          subtitle={showSubtitles ? feature.subtitle : undefined}
          availability={feature.availability}
          compactHeight={compactHeight}
        />
      ))}

      {showDivider && hasExcludedFeatures && hasIncludedOrSellableFeatures && (
        <Frame mb={4}>
          <Divider className={styles.divider} attributes={{ "data-testid": "fareFeatureDivider" }} />
        </Frame>
      )}

      {excludedFeatures.map((feature) => (
        <FareFeature
          key={feature.name}
          content={feature.label}
          availability={feature.availability}
          IconSVG={feature.icon}
          compactHeight={compactHeight}
        />
      ))}
    </>
  );
};

export default FareFeatures;
