const LAST_SEEN_CONTEXT_EVENT_ID = "deprecated";
const UNKNOWN_VALUE = "unknown";
const getFlightEventsContext = (contextName, requestId, eventType, eventName, eventValue, pointOfSale, adpalt) => ({
    device_type: UNKNOWN_VALUE,
    /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
    language: UNKNOWN_VALUE,
    ip_country: UNKNOWN_VALUE,
    currency: UNKNOWN_VALUE,
    analytics_session_id: UNKNOWN_VALUE,
    request_id: requestId || UNKNOWN_VALUE,
    context_name: contextName,
    previous_context_id: getLastSeenContextEventId(),
    device_id: UNKNOWN_VALUE,
    client_type: UNKNOWN_VALUE,
    os: UNKNOWN_VALUE,
    app_version: UNKNOWN_VALUE,
    display_resolution: UNKNOWN_VALUE,
    network_type: UNKNOWN_VALUE,
    label: UNKNOWN_VALUE,
    adplat: adpalt || UNKNOWN_VALUE,
    user_id: 0,
    affiliate_id: UNKNOWN_VALUE,
    soylent_email_id: UNKNOWN_VALUE,
    cookies: { BKNG_experiment_seed: UNKNOWN_VALUE },
    context_id: UNKNOWN_VALUE,
    is_internal_traffic: false,
    event_type: eventType,
    event_name: eventName || UNKNOWN_VALUE,
    event_value: eventValue || UNKNOWN_VALUE,
    is_bot: false,
    user_agent: UNKNOWN_VALUE,
    point_of_sale: pointOfSale || UNKNOWN_VALUE,
    session_id: UNKNOWN_VALUE,
    short_lived_session_id: UNKNOWN_VALUE,
    long_lived_session_id: UNKNOWN_VALUE
});
const getLastSeenContextEventId = () => {
    return LAST_SEEN_CONTEXT_EVENT_ID;
};
export { getFlightEventsContext, getLastSeenContextEventId, UNKNOWN_VALUE };
