import { createTrackingUtils } from "../index";
import { EU_COUNTRIES } from "./tracking-helpers";

const utils = createTrackingUtils({
  name: "flights_ace_web_esim_banner",
  defaultStage: 1,
  stages: {
    eligible_customers_on_sr_page: 1,
    genius_1_customers: 2,
    genius_2_customers: 3,
    genius_3_customers: 4,
    eu_customers: 5,
    desktop: 6,
    mobile: 7,
    with_booking: 8,
    viewed_esim_banner_sr_page: 9
  },
  goals: {
    viewed_esim_banner: 1,
    viewed_esim_banner_with_cta: 2,
    clicked_esim_banner_cta: 3,
    // sent_esim_banner_email: 4, // tracked on node layer in email.tsx. Here just for ref.
    redirected_from_email_link: 5
  },
  goalsWithValue: []
});

export function trackEsimBannerStages(isEligible: boolean, pos: string, geniusLevel: number, isMobile: boolean) {
  if (isEligible) {
    utils.stages.eligible_customers_on_sr_page();
    if (EU_COUNTRIES.includes(pos)) {
      utils.stages.eu_customers();
    }
    if (geniusLevel === 1) {
      utils.stages.genius_1_customers();
    } else if (geniusLevel === 2) {
      utils.stages.genius_2_customers();
    } else if (geniusLevel === 3) {
      utils.stages.genius_3_customers();
    }
    if (isMobile) {
      utils.stages.mobile();
    } else {
      utils.stages.desktop();
    }
  }
}

export default utils;
