import { useEffect, useState } from "react";

import { UIXComponentType, UIXNearbyDestination } from "../types";
import { useSearchParamsData } from "./useSearchParamsData";
import useContextName from "hooks/useContextName";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../store";

import { pastBookingUtils } from "@flights/web-api-utils-universal";
import useIsPbFlightDetails from "../hooks/useIsPbFlightDetails";
import useEvents from "../../hooks/useEvents";

export const useNearbyListCall = (componentType: UIXComponentType) => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const { order } = store.order;
  const data = useSearchParamsData(componentType, 3);
  const contextName = useContextName();
  const isPastBooking = pastBookingUtils.isPastBooking(order);
  const isPbFlightDetails = useIsPbFlightDetails();
  const [destinations, setDestinations] = useState<UIXNearbyDestination[]>();
  const [destinationsLoading, setDestinationsLoading] = useState(false);
  const { jsError } = useEvents();

  useEffect(() => {
    if (!isPbFlightDetails && !isPastBooking && data) {
      setDestinationsLoading(true);
      setDestinations([]);
      setDestinationsLoading(false);
    }
  }, [contextName, data, isPbFlightDetails, isPastBooking, jsError]);

  return { destinations, destinationsLoading };
};
