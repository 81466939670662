import useEventTracking from "hooks/useEventTracking";
import useGlobalContext from "hooks/useGlobalContext";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { getEsim } from "store/esim/selectors";
import { getOrder, getOrderId } from "store/order/selectors";
import { getGeniusLevel } from "store/user/selectors";

export const useTrackEsimShowBanner = (hasButton: boolean) => {
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("esim", requestId);
  const order = useSelector(getOrder);
  const geniusLevel = useSelector(getGeniusLevel);
  const esim = useSelector(getEsim);

  useEffect(() => {
    trackV2("show_banner_esim", {
      orderId: order?.orderId,
      order_status: order?.orderStatus,
      genius_level: geniusLevel,
      destination: esim?.validCountry,
      action: hasButton ? "clickable" : "non-clickable",
      valid_until: esim?.validTill,
      placement: "banner"
    });
  }, [esim?.validCountry, esim?.validTill, geniusLevel, hasButton, order?.orderId, order?.orderStatus, trackV2]);
};

export const useEsimClickBanner = () => {
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("esim", requestId);
  const order = useSelector(getOrder);
  const geniusLevel = useSelector(getGeniusLevel);
  const esim = useSelector(getEsim);

  const handleClick = useCallback(
    (hasButton: boolean) => {
      trackV2("click_banner_esim", {
        orderId: order?.orderId,
        order_status: order?.orderStatus,
        genius_level: geniusLevel,
        destination: esim?.validCountry,
        action: hasButton ? "clickable" : "non-clickable",
        valid_until: esim?.validTill
      });
    },
    [trackV2, order, geniusLevel, esim]
  );

  return handleClick;
};

export const useEsimClickEmail = () => {
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("esim", requestId);
  const order = useSelector(getOrder);
  const geniusLevel = useSelector(getGeniusLevel);
  const esim = useSelector(getEsim);

  const handleClick = useCallback(
    (hasButton: boolean) => {
      trackV2("click_email_esim", {
        orderId: order?.orderId,
        order_status: order?.orderStatus,
        genius_level: geniusLevel,
        destination: esim?.validCountry,
        action: hasButton ? "clickable" : "non-clickable",
        valid_until: esim?.validTill
      });
    },
    [trackV2, order, geniusLevel, esim]
  );

  return handleClick;
};
export const useTrackEsimReceiveNotification = () => {
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("esim", requestId);
  const orderId = useSelector(getOrderId);

  useEffect(() => {
    trackV2("receive_notification_esim", {
      orderId
    });
  }, [orderId, trackV2]);
};

export const useEsimClickNotification = () => {
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("esim", requestId);
  const order = useSelector(getOrder);
  const geniusLevel = useSelector(getGeniusLevel);
  const esim = useSelector(getEsim);

  const handleClick = useCallback(
    (hasButton: boolean) => {
      trackV2("click_notification_esim", {
        orderId: order?.orderId,
        order_status: order?.orderStatus,
        genius_level: geniusLevel,
        destination: esim?.validCountry,
        action: hasButton ? "clickable" : "non-clickable",
        valid_until: esim?.validTill
      });
    },
    [trackV2, order, geniusLevel, esim]
  );

  return handleClick;
};
