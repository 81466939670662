import { createTrackingUtils } from "../index";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import { SBSearchType } from "@bookingcom/flights-searchbox";

const utils = createTrackingUtils({
  name: "flights_web_contextualized_sr_loading_desktop",
  stages: {
    all_sr: 2,
    direct: 3,
    meta: 4,
    one_way: 5,
    round_trip: 6,
    multi_stop: 7
  },
  goals: {
    sr_fully_fetched: 1
  },
  defaultStage: 1
});

const trackStages = (searchType: SBSearchType) => {
  utils.stages.all_sr();
  isOfMetaOrigin() ? utils.stages.meta() : utils.stages.direct();

  if (searchType === "ONEWAY") {
    utils.stages.one_way();
  } else if (searchType === "ROUNDTRIP") {
    utils.stages.round_trip();
  } else if (searchType === "MULTISTOP") {
    utils.stages.multi_stop();
  }
};

export default { ...utils, trackStages };
