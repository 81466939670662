import useProfiler from "hooks/useProfiler";
import { createTrackingUtils } from "..";

const utils = createTrackingUtils({
  name: "flights_web_flex_banner_dsa_subtitle",
  stages: {
    banner_rendered: 1,
    desktop_direct: 2,
    mobile_meta: 3,
    mobile_direct: 4,
    better_price: 5,
    positive_reinforcement: 6
  },
  defaultStage: 1,
  goals: {
    click_on_banner_item: 1
  },
  goalsWithValue: []
});

const { stages } = utils;

export const useTrackFlexBannerDSASubtitle = ({ foundBetterPrices }: { foundBetterPrices: boolean }) => {
  const { isDesktop, isDirect, isMobile, isMeta } = useProfiler();

  if (isDesktop() && isDirect()) stages.desktop_direct();
  if (isMobile() && isDirect()) stages.mobile_direct();
  if (isMobile() && isMeta()) stages.mobile_meta();
  if (foundBetterPrices) {
    stages.better_price();
  } else {
    stages.positive_reinforcement();
  }
};

export default utils;
