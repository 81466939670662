const throttle = <F extends (...params: any[]) => void>(fn: F, delay: number) => {
  let timeoutID: ReturnType<typeof setTimeout> | null = null;
  let lastCall = 0;

  return function (this: any, ...args: any[]) {
    const now = Date.now();

    if (now - lastCall >= delay) {
      if (timeoutID) {
        clearTimeout(timeoutID);
        timeoutID = null;
      }
      lastCall = now;
      fn.apply(this, args);
    } else {
      if (!timeoutID) {
        const remaining = delay - (now - lastCall);
        timeoutID = setTimeout(() => {
          lastCall = Date.now();
          timeoutID = null;
          fn.apply(this, args);
        }, remaining);
      }
    }
  } as F;
};

export default throttle;
