import React, { useEffect } from "react";
import { useRemoteComponent } from "@bookingcom/remote-component";
import isEmpty from "lodash/isEmpty";
import useEvents from "hooks/useEvents";

const RewardsLandSheet = () => {
  const { grumble } = useEvents();

  const { Component, isLoading, error } = useRemoteComponent({
    project: "web-shell",
    service: "header-mfe",
    path: "/MarketingRewards/Flights/RewardsLandSheet",
    type: "capla"
  });

  useEffect(() => {
    if (!isLoading && error && !isEmpty(error)) {
      grumble("[CM Rewards / Flights error]: ", { error: error.toString() });
    }
  }, [isLoading, error, grumble]);

  return <Component />;
};

export default RewardsLandSheet;
