import React, { useCallback, useMemo, useState } from "react";
import { ArrowsLeftRightIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { Icon } from "@bookingcom/bui-react";
import useOriginDestination from "./useOriginDestination.js";
import { t } from "@bookingcom/lingojs-core";
import styles from "./ButtonFlip.module.css";
const ButtonFlip = (props) => {
    const { segmentIndex, className } = props;
    const { swapSegment, trackV2, i18n } = useOriginDestination({ segmentIndex });
    const [rotate, setRotate] = useState(false);
    const handleOnClick = useCallback(() => {
        setRotate(!rotate);
        swapSegment();
        trackV2("click_swap");
    }, [rotate, swapSegment, trackV2]);
    const iconClass = useMemo(() => {
        return [styles.flipIcon, rotate && styles.flipButtonIcon].filter(Boolean).join(" ");
    }, [rotate]);
    const buttonClass = useMemo(() => {
        return [styles.flipButton, className].filter(Boolean).join(" ");
    }, [className]);
    return (React.createElement("button", { type: "button", "aria-label": i18n.trans(t("a11y_flights_index_search_box_switch_aria_label")), className: buttonClass, onClick: handleOnClick },
        React.createElement(Icon, { svg: ArrowsLeftRightIcon, color: "action", size: "medium", attributes: { "data-button-flip-origin-destination": "" }, className: iconClass })));
};
export default ButtonFlip;
