import { useSelector } from "react-redux";
import { createTrackingUtils } from "../index";
import { getSearchId } from "../../../store/searchResults/selectors";
import { flightsSessionStore } from "utils/session-store";
import { useEffect } from "react";
import { useMdotValueTrackDefaultStages, MDotValueStage } from "./default_stages";
import useGlobalContext from "hooks/useGlobalContext";

const EXP_NAME = "flights_mdotvalue_sr_retain_scroll_on_close_flight_details";

const expTrackingUtils = createTrackingUtils({
  name: EXP_NAME,
  defaultStage: 1,
  stages: {
    scrolled_past_first_card_and_saw_flight_details: 1,
    clicked_close_flight_details_modal: 2,
    [MDotValueStage.PAID_TRAFFIC]: 3, // meta + ppc
    [MDotValueStage.US_TRAFFIC]: 4,
    non_us_traffic: 5,
    [MDotValueStage.USER_SIGNED_IN]: 6,
    [MDotValueStage.USER_SIGNED_OUT]: 7,
    [MDotValueStage.ANDROID_DEVICE]: 8,
    [MDotValueStage.IOS_DEVICE]: 9
  },
  goals: {
    scrolled_past_first_card: 1,
    clicked_on_card_after_first: 2,
    clicked_on_card_after_fifth: 3,
    reopened_flight: 4,
    clicked_on_lower_ranked_flight: 5
  },
  goalsWithValue: ["js_flights_sr_card_index_clicked"]
});

const SESSION_STORE_KEY = "highest_seen_card_index";
const SESSION_STORE_INITIAL_STATE = {
  lastSearchId: "",
  highestSeenCardIndex: -1,
  isPastFirstCard: false,
  flightsSeen: [] as string[]
};
type SessionStoreState = typeof SESSION_STORE_INITIAL_STATE;
const getState = (): SessionStoreState => flightsSessionStore.get(SESSION_STORE_KEY) || SESSION_STORE_INITIAL_STATE;
const setState = (newStateChanges: Partial<SessionStoreState>): void =>
  flightsSessionStore.set(SESSION_STORE_KEY, {
    ...getState(),
    ...newStateChanges
  });

const checkIfReopenedFlight = (searchId: string, cardIndex: number) => {
  const { flightsSeen } = getState();
  const _flightId = `${searchId}_${cardIndex}`;

  if (flightsSeen.includes(_flightId)) {
    expTrackingUtils.goals.reopened_flight();
  } else {
    setState({
      flightsSeen: [...flightsSeen, _flightId]
    });
  }
};

export function useTracking(currentCardIndex: number, isPastFirstCard: boolean) {
  const currentSearchId = useSelector(getSearchId);
  const { ipCountry } = useGlobalContext();
  const trackDefaultMdovalueStages = useMdotValueTrackDefaultStages();

  useEffect(() => {
    if (currentCardIndex > 0) {
      return;
    }

    if (isPastFirstCard) {
      expTrackingUtils.goals.scrolled_past_first_card();
    } else {
      return;
    }

    setState({ isPastFirstCard });
  }, [currentCardIndex, isPastFirstCard]);

  return function trackOnClick() {
    if (!currentSearchId) {
      return;
    }

    checkIfReopenedFlight(currentSearchId, currentCardIndex);

    const { lastSearchId, highestSeenCardIndex, isPastFirstCard: isPastFirstCardInState } = getState();
    if (!isPastFirstCardInState) {
      return;
    }

    if (!lastSearchId || highestSeenCardIndex === -1 || lastSearchId !== currentSearchId) {
      setState({
        lastSearchId: currentSearchId,
        highestSeenCardIndex: currentCardIndex
      });
    } else if (currentCardIndex > highestSeenCardIndex) {
      setState({ highestSeenCardIndex: currentCardIndex });
      if (currentCardIndex > 0) {
        // the user checked one flight and after checked another flight
        // that is lower ranked that that
        expTrackingUtils.goals.clicked_on_lower_ranked_flight();
      }
    } else {
      return;
    }

    trackDefaultMdovalueStages(expTrackingUtils.stages);

    if (ipCountry?.toLowerCase() !== "US") {
      expTrackingUtils.stages.non_us_traffic();
    }

    expTrackingUtils.stages.scrolled_past_first_card_and_saw_flight_details();
    expTrackingUtils.goalsWithValue.js_flights_sr_card_index_clicked(currentCardIndex);

    if (currentCardIndex > 0) {
      expTrackingUtils.goals.clicked_on_card_after_first();

      if (currentCardIndex > 6) {
        expTrackingUtils.goals.clicked_on_card_after_fifth();
      }
    }
  };
}
export function isVariant() {
  const { isPastFirstCard } = getState();

  if (!isPastFirstCard) {
    return false;
  }

  expTrackingUtils.stages.clicked_close_flight_details_modal();
  return expTrackingUtils.trackWithDefaultStage() > 0;
}

export default {
  useTracking,
  isVariant
};
