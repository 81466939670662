import { useCallback, useContext } from "react";
import { SearchBoxContext } from "../SearchBoxContext/index.js";
import { t } from "@bookingcom/lingojs-core";
import { validateSegment } from "../../utils/validator.js";
const useLocations = (props) => {
    const { segmentIndex, type } = props;
    const { segments, i18n, trackV2, isSubmitted, setSegmentFrom, setSegmentTo, searchType, multiSelectVariant } = useContext(SearchBoxContext);
    const segment = segments?.[segmentIndex];
    const selectedLocations = segment?.[type] || [];
    let placeholderText = undefined;
    if (selectedLocations.length == 0) {
        placeholderText =
            type === "from"
                ? i18n.trans(t("flights_search_origin_prompt"))
                : i18n.trans(t("flights_search_destination_prompt"));
    }
    const selectedLocationsText = selectedLocations.length
        ? selectedLocations.map((loc) => {
            let code, name;
            if (loc.type === "CITY" && selectedLocations.length === 1) {
                code = loc.name;
                name = i18n.trans(t("flights_multi_select_all_airports"));
            }
            else {
                code = loc.code;
                name = loc.name || loc["cityName"];
            }
            return { code, name };
        })
        : undefined;
    const isLocationSelected = selectedLocations.length > 0;
    const errorMessage = isSubmitted && !isLocationSelected && !validateSegment(segment, segmentIndex, searchType)
        ? i18n.trans(t("flights_search_empty_airport"))
        : undefined;
    const setLocations = useCallback((locations) => {
        type === "from" ? setSegmentFrom(locations, segmentIndex) : setSegmentTo(locations, segmentIndex);
    }, [type, setSegmentFrom, setSegmentTo, segmentIndex]);
    return {
        setLocations,
        selectedLocations,
        i18n,
        isLocationSelected,
        isSubmitted,
        placeholderText,
        selectedLocationsText,
        trackV2,
        errorMessage,
        multiSelectVariant
    };
};
export default useLocations;
