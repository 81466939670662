import { StoreState } from "store/index";

export const getOrder = (state: StoreState) => state.order.order;
export const getOrderCountry = (state: StoreState) => state.order.order?.salesInfo?.country;
export const getOrderSupplierType = (state: StoreState) => state.order.order?.supplierType;
export const getOrderId = (state: StoreState) => state.order.order?.orderId;
export const getOrderFetchStatus = (state: StoreState) => state.order.fetchStatus;
export const getAddOnOrder = (state: StoreState) => state.order.addOnOrder;
export const getNoOfAdults = (state: StoreState) =>
  state.order.order?.passengers.filter((p) => p.type === "ADULT").length;
export const getNoOfChildren = (state: StoreState) =>
  state.order.order?.passengers.filter((p) => p.type === "KID").length;
export const getFlightSegments = (state: StoreState) => state.order.order?.airOrder.flightSegments;
export const getArrivalPlace = (state: StoreState) => state.order.order?.airOrder.flightSegments?.[0]?.arrivalAirport;
export const getTripType = (state: StoreState) =>
  state.order.order?.airOrder.flightSegments &&
  state.order.order?.airOrder.flightSegments.length > 0 &&
  state.order.order?.airOrder.flightSegments.length === 1
    ? "ONEWAY"
    : state.order.order?.airOrder.flightSegments?.length === 2
    ? "ROUNDTRIP"
    : "MULTISTOP";
export const getLastDepartureDate = (state: StoreState) =>
  getTripType(state) === "ROUNDTRIP"
    ? state.order.order?.airOrder.flightSegments?.[
        state.order.order?.airOrder.flightSegments.length - 1
      ].arrivalTime?.split("T")[0]
    : state.order.order?.airOrder.flightSegments?.[0].departureTime?.split("T")[0];
export const getArrivalCountryCode = (state: StoreState) =>
  state.order.order?.airOrder.flightSegments?.[0].arrivalAirport.country;
export const getOrderStatus = (state: StoreState) => state.order.order?.orderStatus;
export const getOrderBookedAt = (state: StoreState) => state.order.order?.airOrder.bookedAt;
