import React, { useCallback } from "react";
import useCabinClass from "./useCabinClass.js";
import { Select } from "../Shell/index.js";
export default function CabinClassSelect() {
    const { cabinClass, cabinClassOptions, setCabinClass, cabinClassTitle } = useCabinClass();
    const handleOnChange = useCallback((name) => {
        setCabinClass(name);
    }, [setCabinClass]);
    return (React.createElement(Select, { value: cabinClass, name: "sr_cabin_class", "data-ui-name": "cabin_class_input", "aria-label": cabinClassTitle, options: cabinClassOptions, onChange: (e) => {
            handleOnChange(e.target.value);
        } }));
}
