let serviceDevOverrideServices = {
  services: []
};
try {
  serviceDevOverrideServices = require("./services.json");
} catch (error) {
  console.error("Error getting service urls:", error); // eslint-disable-line
}

export const FE_DEPENDENCIES_SERVICES = serviceDevOverrideServices?.services || [];
