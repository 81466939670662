import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "store";

export const getSubsidizedFares = (state: StoreState) => state.flightDetails.flight?.subsidizedFares;
export const getFlightPointOfSale = (state: StoreState) => state.flightDetails.flight?.pointOfSale;
export const getHasFlight = (state: StoreState) => !!state.flightDetails.flight;
export const getFlight = (state: StoreState) => state.flightDetails.flight;
export const getBrandedFareOffers = (state: StoreState) => state.flightDetails.flight?.brandedFareOffers;
export const getAncillaries = (state: StoreState) => state.flightDetails.flight?.ancillaries;
export const getFlightDetailsFetchStatus = (state: StoreState) => state.flightDetails.fetchStatus;
export const getFlightDetailsSegments = (state: StoreState) => state.flightDetails.flight?.segments;
export const getCanSkipExtrasPage = (state: StoreState) => state.flightDetails.flight?.displayOptions?.skipExtrasPage;
export const getArrivalPlace = createSelector(
  (state: StoreState) => state.flightDetails.flight?.segments[0]?.arrivalAirport,
  (arrivalAirport) => {
    return { code: arrivalAirport?.code, type: arrivalAirport?.type };
  }
);
export const getTripType = (state: StoreState) => state.flightDetails.flight?.tripType;
export const getArrivalCountry = (state: StoreState) => state.flightDetails.flight?.segments[0]?.arrivalAirport.country;
export const getLastDepartureDate = (state: StoreState) =>
  getTripType(state) === "ROUNDTRIP"
    ? state.flightDetails.flight?.segments[state.flightDetails.flight?.segments.length - 1].arrivalTime?.split("T")[0]
    : state.flightDetails.flight?.segments[0].arrivalTime?.split("T")[0];
