import { t } from "@bookingcom/lingojs-core";
export const NON_INCLUDED_FEATURES_AIRLINES = ["UA", "DL", "AF", "KL"];
export const SELLABLE_FEATURES_AIRLINES = ["AF", "KL"];
export const CFAR_TARGET_COUNTRIES = ["se", "de", "it", "fr"];
export const CFAR_TEST_COUNTRIES = ["dk", "fi", "nl", "be", "es", "pt"];
export const CFAR_NEW_COUNTRIES = ["hr", "lu", "gb"];
export const LEGAL_ENTITY_NAMES = {
    us: "GotoGate Inc.",
    nl: "Supersaver Travel B.V.",
    dk: "Den Danske Rejsegruppe",
    se: "Svenska Resegruppen AB",
    de: "OY SRG Finland AB",
    it: "OY SRG Finland AB",
    fi: "OY SRG Finland AB",
    fr: "OY SRG Finland AB",
    es: "OY SRG Finland AB",
    gb: "OY SRG Finland AB",
    ch: "Gotogate International AB",
    at: "OY SRG Finland AB",
    au: "GotoGate Pty. Ltd.",
    be: "OY SRG Finland AB",
    pt: "OY SRG Finland AB",
    th: "Gotogate International AB",
    ro: "OY SRG Finland AB",
    in: "Gotogate International AB",
    no: "Marco Polo Travel AS",
    pl: "Gotogate International AB",
    ae: "Gotogate Flights Ltd",
    ie: "OY SRG Finland",
    cl: "Gotogate International AB",
    cz: "Gotogate International AB",
    ca: "Flight Network Ltd",
    hu: "Gotogate International AB",
    br: "Gotogate International AB",
    lu: "OY SRG Finland AB",
    ua: "OY SRG Finland AB",
    sa: "Gotogate International AB",
    hk: "Gotogate International AB",
    kw: "Gotogate International AB",
    jp: "Gotogate International AB",
    qa: "Gotogate International AB",
    ph: "Gotogate International AB",
    rs: "OY SRG Finland AB"
};
export const INCLUDED_BAGGAGES_LIST = ["PERSONAL_ITEM", "HAND", "CHECKED_IN"];
// Making Custom Baggage Features
// Keeping feature codes 4 digit and uppercase to differentiate from ETG codes
export const BAGGAGE_FEATURE_MAP = {
    PERSONAL_ITEM: {
        featureName: "PERSONAL_BAGGAGE",
        category: "BAGGAGE",
        code: "BK03"
    },
    HAND: {
        featureName: "CABIN_BAGGAGE",
        category: "BAGGAGE",
        code: "BK02"
    },
    CHECKED_IN: {
        featureName: "CHECK_BAGGAGE",
        category: "BAGGAGE",
        code: "BK01"
    }
};
const lufthansaDescription = (airline) => t("flights_apex_bf_greenfare_tooltip_lufthansa", { variables: { airline_name: airline } });
export const SUSTAINABILITY_FEATURE_DESCRIPTION_MAP = {
    LH: lufthansaDescription,
    OS: lufthansaDescription,
    LX: lufthansaDescription,
    SN: lufthansaDescription,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "4Y": lufthansaDescription,
    EN: lufthansaDescription,
    SK: (airline) => t("flights_apex_bf_greenfare_tooltip_sas", { variables: { airline_name: airline } })
};
export const SUSTAINABILITY_AIRLINES = Object.keys(SUSTAINABILITY_FEATURE_DESCRIPTION_MAP);
export const ALLOWED_INSURANCE_TYPES = [
    "COMPREHENSIVE_INSURANCE_COVER_GENIUS",
    "TRAVEL_INSURANCE_COVER_GENIUS"
];
