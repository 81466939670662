import useIsInViewport from "hooks/useIsInViewport";
import React, { useRef, useEffect, useCallback } from "react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../store";
import FlexibleDateBestPriceInner from "./FlexibleDateBestPriceInner";
import { useGetFlexDatesData } from "./useGetFlexDatesData";
import { trackExperiment, trackExperimentStage } from "utils/et";
import useRouteName from "hooks/useRouteName";
import useUserAgent from "hooks/useUserAgent";
import flights_web_flex_banner_dsa_subtitle from "utils/experiments/funnel/flights_web_flex_banner_dsa_subtitle";

const FlexibleDateBestPrice = ({ shouldRender }: { shouldRender: boolean }) => {
  const data = useGetFlexDatesData();
  const {
    searchCriteria: { adults, children },
    flexibleDateMinPrice: { flexibleDateMinPrice = [] }
    // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  } = useStore();
  const hiddenElementRef = useRef(null);
  const isInViewport = useIsInViewport(hiddenElementRef.current);
  const goalsTrackedRef = useRef(false);
  const inViewportTrackedRef = useRef(false);
  const scrollRef = useRef(false);
  const routeName = useRouteName();
  const { isMobile } = useUserAgent();

  useEffect(() => {
    if (data) {
      if (routeName === "searchresults" && isMobile) {
        trackExperimentStage("flights_web_q4_blackout", 1);
        trackExperimentStage("flights_web_q4_blackout", 3);
        trackExperimentStage("flights_web_q4_blackout", 5);
      }

      if (!inViewportTrackedRef.current && isInViewport) {
        inViewportTrackedRef.current = true;
      }

      if (!goalsTrackedRef.current) {
        goalsTrackedRef.current = true;
      }
    }
  }, [adults, children.length, data, flexibleDateMinPrice, isInViewport, routeName, isMobile]);

  const onCarouselScroll = useCallback(() => {
    if (!scrollRef.current) {
      scrollRef.current = true;
    }
  }, []);

  if (!shouldRender || !data || !!trackExperiment("flights_web_q4_blackout")) return null;

  const { dates, currentBestPrice, cheaperCount, isOneWay, departureDate, returnDate } = data;

  const onItemClick = () => {
    flights_web_flex_banner_dsa_subtitle.goals.click_on_banner_item();
  };

  return (
    <>
      <div ref={hiddenElementRef} aria-hidden></div>

      <FlexibleDateBestPriceInner
        carouselSize="large"
        onCarouselScroll={onCarouselScroll}
        dates={dates}
        currentBestPrice={currentBestPrice}
        cheaperCount={cheaperCount}
        isOneWay={isOneWay}
        departureDate={departureDate}
        returnDate={returnDate}
        onItemClick={onItemClick}
      />
    </>
  );
};

export default FlexibleDateBestPrice;
