// Generally experiments run by MdotValue (https://teams.booking.com/team/60011316)
// will have most of the in common. This hook will help us keep the code simpler to implement.

import useGlobalContext from "hooks/useGlobalContext";
import useUserAgent from "hooks/useUserAgent";
import { useHistory } from "react-router-dom";
import { isDirect, isOfMetaOrigin, isOfPPCOrigin } from "utils/marketing-url-params";

const KEY_MARKET_COUNTRY_CODES = ["us", "fr", "in", "it", "de", "gb", "es", "br"];

export enum MDotValueStage {
  USER_ELIGIBLE = "userEligible",
  USER_SIGNED_IN = "userSignedIn",
  USER_SIGNED_OUT = "userSignedOut",
  DIRECT_TRAFFIC = "directTraffic",
  PAID_TRAFFIC = "paidTraffic",
  META_TRAFFIC = "metaTraffic",
  PPC_TRAFFIC = "ppcTraffic",
  US_TRAFFIC = "UsTraffic",
  KEY_MARKET_TRAFFIC = "keyMarketTraffic",
  LAST_MINUTE_BOOKER = "lastMinuteBooker",
  SHORT_TERM_BOOKER = "shortTermBooker",
  LANDING_USER = "landingUser",
  IOS_DEVICE = "iosDevice",
  ANDROID_DEVICE = "androidDevice"
}

export function useMdotValueTrackDefaultStages() {
  const mdotValueDefaultConditions = useMDotValueDefaultConditions();

  return (stages: { [key: string]: Function }) => {
    mdotValueDefaultConditions.forEach((value, conditionName) => {
      if (value && conditionName in stages) {
        stages[conditionName as keyof typeof stages]();
      }
    });
  };
}

export function useMDotValueDefaultConditions(): Map<MDotValueStage, boolean> {
  const { isMobile } = useUserAgent();
  const history = useHistory();
  const { auth, ipCountry } = useGlobalContext();

  if (!isMobile) {
    return new Map(Object.keys(MDotValueStage).map((stage) => [stage as MDotValueStage, false]));
  }

  const isSignedIn = auth.isAuthenticatedOnWeb;
  const isKeyMarket = Boolean(ipCountry && KEY_MARKET_COUNTRY_CODES.includes(ipCountry.toLowerCase()));
  const isUsTraffic = Boolean(ipCountry && ipCountry.toLowerCase() === "us");
  const isIOs = Boolean(navigator?.userAgent && /iphone|ipad|ipod|ios/.test(navigator?.userAgent?.toLocaleLowerCase()));

  return new Map([
    [MDotValueStage.USER_SIGNED_IN, isSignedIn],
    [MDotValueStage.USER_SIGNED_OUT, !isSignedIn],
    [MDotValueStage.US_TRAFFIC, isUsTraffic],
    [MDotValueStage.KEY_MARKET_TRAFFIC, isKeyMarket],
    [MDotValueStage.DIRECT_TRAFFIC, isDirect()],
    [MDotValueStage.META_TRAFFIC, isOfMetaOrigin()],
    [MDotValueStage.PPC_TRAFFIC, isOfPPCOrigin()],
    [MDotValueStage.PAID_TRAFFIC, isOfPPCOrigin() || isOfMetaOrigin()],
    [MDotValueStage.LANDING_USER, history.length <= 1],
    [MDotValueStage.IOS_DEVICE, isIOs],
    [MDotValueStage.ANDROID_DEVICE, !isIOs]
  ]);
}
