import useProfiler from "hooks/useProfiler";
import { createTrackingUtils } from "..";
import { UIPriceBreakdown } from "@flights/types";

const utils = createTrackingUtils({
  name: "flights_web_price_breakdown_travellers_split",
  stages: {
    fd_with_children: 1,
    open_price_breakdown: 2,
    direct: 3,
    meta: 4,
    desktop: 5,
    mobile: 6
  },
  defaultStage: 1,
  goals: {
    open_detailed_breakdown_checkout: 1
  },
  goalsWithValue: []
});

export const useTrackPriceBreakdownSplitStages = ({ shouldTrack }: { shouldTrack: boolean }) => {
  const { isDirect, isMeta, isDesktop, isMobile, isWithInfants, isWithKids } = useProfiler();

  if (!shouldTrack) return;

  if (isWithInfants() || isWithKids()) {
    utils.stages.open_price_breakdown();
    isDirect() && utils.stages.direct();
    isMeta() && utils.stages.meta();
    isDesktop() && utils.stages.desktop();
    isMobile() && utils.stages.mobile();
  }
};

export const trackOpenDetailedBreakdownCheckout = ({
  childrenPriceBreakDown
}: {
  childrenPriceBreakDown?: UIPriceBreakdown[];
}) => {
  if (
    childrenPriceBreakDown &&
    childrenPriceBreakDown.length > 0 &&
    childrenPriceBreakDown.some((c) => c.total.units <= 0)
  ) {
    utils.goals.open_detailed_breakdown_checkout();
  }
};

export default utils;
