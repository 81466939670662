import { createTrackingUtils } from "../index";

const utils = createTrackingUtils({
  name: "flights_apex_web_insurance_infant_legal_requirements",
  stages: {
    insurance_shown: 1,
    insurance_shown_with_child: 2,
    price_breakdown_clicked_with_insurance_added: 3,
    insurance_shown_with_child_ddot: 4,
    insurance_shown_with_child_mdot: 5
  },
  goals: {},
  defaultStage: 1
});

const trackInsuranceShown = (hasKidTraveller: boolean, isMobile: boolean) => {
  utils.stages.insurance_shown();
  if (hasKidTraveller) {
    utils.stages.insurance_shown_with_child();
    if (isMobile) {
      utils.stages.insurance_shown_with_child_mdot();
    } else {
      utils.stages.insurance_shown_with_child_ddot();
    }
  }
};

export default { ...utils, trackInsuranceShown };
