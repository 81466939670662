import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { trackExperiment, trackExperimentStage } from "utils/et";
import { UILocation } from "@flights/types";
import { State as SearchState } from "../store/search/state";
import { useStore } from "store";
import { getSearchApiMarketingParams, isOfMetaOrigin } from "utils/marketing-url-params";
import {
  LOCATION_IATA_CODE_AND_TYPE_SEPARATOR,
  MULTISTOP_IATA_LIST_SEPARATOR,
  PARAM_LIST_SEPARATOR
} from "@flights/constants";
import { flightsSessionStore } from "utils/session-store";

const AIRPORT_REDIRECTS = {
  ALV: "BCN",
  ARW: "TSR",
  ASQ: "AUS",
  BNU: "NVT",
  BZC: "RIO",
  CEQ: "NCE",
  CPB: "MTR",
  CTD: "CDT",
  CVE: "MTR",
  DAI: "IXB",
  EXM: "LEA",
  GCY: "TYS",
  GXQ: "BBA",
  JAF: "OKC",
  JRS: "TLV",
  NCP: "MNL",
  NDC: "HYD",
  PCM: "CUN",
  PFN: "ECP",
  PRY: "JNB",
  PUX: "PMC",
  QIJ: "OVD",
  QRL: "AGP",
  RRK: "IXR",
  RRO: "NAP",
  SMM: "TWU",
  SOB: "VIE",
  TAR: "BDS",
  TUY: "CUN",
  UKY: "OSA",
  WSY: "HTI",
  XSA: "CFU",
  YBA: "YYC",
  ZPC: "ZCO"
};

function getLocationCodes(locations: UILocation[]): string {
  return locations
    .map(({ code, type }) => {
      if (code in AIRPORT_REDIRECTS) {
        if (!!trackExperiment("flights_unknown_inactive_locations_supply_redirect")) {
          // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
          code = AIRPORT_REDIRECTS[code];
        }
        trackExperimentStage("flights_unknown_inactive_locations_supply_redirect", 1);
      }
      return [code, type].filter(Boolean).join(LOCATION_IATA_CODE_AND_TYPE_SEPARATOR);
    })
    .join(PARAM_LIST_SEPARATOR);
}

function getSearchAPIParams(search: SearchState, query: string) {
  const params = new URLSearchParams(query);
  const { searchSegments } = search;

  // Add ?from=x&to=y parameters provided via URL path
  // They MUST override query parameters to ensure invalid routes like /FOO-BAR/ do not return results

  if (search.type === "MULTISTOP") {
    const fromCodes = searchSegments.map((eachSegment) => getLocationCodes(eachSegment.from));
    const toCodes = searchSegments.map((eachSegment) => getLocationCodes(eachSegment.to));
    params.set("from", fromCodes.join(MULTISTOP_IATA_LIST_SEPARATOR));
    params.set("to", toCodes.join(MULTISTOP_IATA_LIST_SEPARATOR));
  } else {
    //In case of oneway or roundtrip there will be only one segment with {from,to}
    const tripSegment = searchSegments[0];
    params.set("from", getLocationCodes(tripSegment.from));
    params.set("to", getLocationCodes(tripSegment.to));
  }

  if (trackExperiment("flights_web_inbound_outbound_sr") && search.type === "ROUNDTRIP") {
    params.set("outboundOnly", "1");
  }

  const marketingParams = getSearchApiMarketingParams();
  for (const marketingParam in marketingParams) {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    params.set(marketingParam, marketingParams[marketingParam]);
  }

  params.set("enableVI", "1");

  if (
    Boolean(trackExperiment("flights_web_cat_pin_flight_www")) &&
    isOfMetaOrigin() &&
    flightsSessionStore.get("landingOfferKey")
  ) {
    params.set("offerKeyToHighlight", flightsSessionStore.get("landingOfferKey"));
  }

  // Removing baseOfferToken from url when selecting a different offer token from branded fares on search
  params.delete("baseOfferToken");
  params.delete("flexibleTicket");
  params.delete("cancelForAnyReason");

  return params;
}

export function useSearchAPIParams() {
  /* eslint-disable react-hooks/exhaustive-deps */
  /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
  /**
   * BEWARE: We intentionally omit the store search state from the dependency list.
   *
   * The way search currently works, is that we modify the state directly when operating
   * the inputs in the searchbox. The search is only performed when the URL changes, and the
   * URL change (history.push) is only applied on clicking the 'Search' button.
   *
   * tl;dr changing the store does NOT result in a new URL, or search results being reloaded
   * (but it should, if we want to do this the reactive way™)
   *
   * FIXME: have the searchbox hold its own state UNTIL THE USER applies the new search, and
   * only then we send it to our global store; alternatively have a `draftSearch`, the same
   * approach we used to solve this problem in search filters.
   *
   */
  const { search } = useStore();
  const history = useHistory();

  return useMemo(() => {
    const params = getSearchAPIParams(search, history.location.search);

    return params;
  }, [history.location]);
}
