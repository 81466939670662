import { useCallback } from "react";
import { useI18n } from "@bookingcom/lingojs-react";
import capitalize from "lodash/capitalize";
import { formattingUtils } from "@flights/web-api-utils-universal";

export default function useCabinClassName() {
  const i18n = useI18n();

  const getCabinClassName = useCallback(
    (cabinClass: string = ""): string => {
      // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
      const mappedClassName = formattingUtils.getCabinClassName(cabinClass);
      /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
      // TODO : make sure all cabin classes are covered with copy tags
      return !!mappedClassName ? i18n.trans(mappedClassName) : capitalize(cabinClass);
    },
    [i18n]
  );

  return getCabinClassName;
}
