import { CLIENT_SIDE_FEATURES } from "./features.js";
import { PARAM_LIST_SEPARATOR, MULTISTOP_IATA_LIST_SEPARATOR, URL_IATA_LIST_SEPARATOR, LOCATION_IATA_CODE_AND_TYPE_SEPARATOR, MANDATORY_SEARCH_PARAMS_ONEWAY } from "./params.js";
export const X_BOOKING_TOPIC_HEADER = "X-Booking-Topic";
export const X_BOOKING_ORIGINAL_CLIENT_ID_HEADER = "X-Booking-Original-Client-Id";
export const ENVOY_URL = "http://127.0.0.1:9211";
// Dev overrides
// See https://gitlab.booking.com/flights/main/-/wikis/Dev-Service-Override
export const X_BOOKING_FLIGHTS_DEV_SERVICE_OVERRIDE = "X-Booking-Flights-Dev-Service-Override";
export const SERVICES_WITH_DEV_OVERRIDE_CAPABILITY = [
    "search",
    "order",
    "airdesk",
    "pricing",
    "cross-sell",
    "ancillary-content"
];
// Client Hints, header and supported features list.
export const X_BOOKING_FLIGHTS_CLIENT_HINTS = "X-Booking-Flights-Client-Hints";
export const FLIGHTS_CLIENT_HINTS_FEATURES = ["price_change_v2"];
export const F5_SOURCE_IP_HEADER = "F5SourceIP";
export const USER_AGENT_HEADER = "User-Agent";
//  Request tracing XCall field names
export const XCALL_BOOKING_REQUEST_ID = "request_id";
export const XCALL_BOOKING_PARENT_REQUEST_ID = "parent_request_id";
export const XCALL_BOOKING_REQUEST_TREE_ID = "request_tree_id";
export const RTL_LANGUAGES = ["ar", "he"];
export const DEFAULT_SUPPLIER = "ETG";
export const WEBVIEW_AFFILIATE_IDS = {
    // iOS
    336317: "Production Ipad",
    332731: "Production Iphone",
    336318: "Test Env Ipad",
    331867: "Test Env Iphone",
    // Android
    338019: "android-debug",
    337862: "android-production",
    911061: "huawei",
    911062: "huawei",
    911063: "huawei",
    911064: "huawei",
    911065: "huawei",
    911066: "huawei",
    911067: "huawei",
    911068: "huawei",
    1295056: "huawei",
    1514475: "huawei",
    1433611: "huawei",
    1488605: "huawei",
    1488606: "huawei",
    1488607: "huawei",
    1488611: "huawei",
    1488613: "huawei",
    1488614: "huawei",
    1488615: "huawei",
    1488616: "huawei",
    1524737: "huawei",
    1664197: "huawei",
    1613134: "huawei",
    1613118: "huawei",
    1911922: "huawei",
    1926618: "huawei",
    1190801: "xiaomi",
    1355643: "xiaomi",
    1441263: "xiaomi",
    1647594: "xiaomi",
    1647678: "xiaomi",
    1253366: "sfr",
    1200544: "telecom_italia",
    1237832: "sony_mobile",
    1554019: "sony_mobile",
    376323: "acer",
    378919: "acer",
    1180914: "acer",
    1518130: "samsung_africa", // South Africa
    1518533: "samsung_africa", // Botswana
    1518534: "samsung_africa", // Namibia
    1518536: "samsung_africa", // Lesotho
    1518538: "samsung_africa", // Zimbabwe
    1518540: "samsung_africa", // Mozambique
    1518542: "samsung_africa", // Mauritius
    1518543: "samsung_africa", // Madagascar
    1518546: "samsung_africa", // Reunion
    1518548: "samsung_africa", // Zambia
    1518549: "samsung_africa", // Malawi
    1650850: "samsung_china",
    1908031: "samsung_china",
    1653279: "samsung_gulf",
    1708168: "samsung_brazil",
    1557215: "telecom_italia",
    1588965: "telefonica",
    1647238: "telefonica",
    1653178: "oppo_china",
    1654971: "meo",
    1237790: "p4",
    1237797: "polkomtel",
    1696059: "wiko",
    1699791: "play_pl",
    1674138: "vivo",
    1748961: "bouygues_telecom",
    1773023: "samsung_india",
    1810236: "lg",
    386457: "prestigio",
    1831078: "lg_europe",
    1831080: "lg_mea",
    1831081: "lg_asia",
    1831082: "lg_latin",
    1831083: "lg_korea",
    1831084: "lg_japan",
    1831086: "lg_india",
    1831087: "lg_cis",
    1843168: "telefonica",
    1775508: "orange",
    1874854: "tcl",
    1887228: "xiaomi", // Xiaomi India
    1926170: "samsung",
    1943837: "xiaomi"
};
// Visible at https://graphite.booking.com/ tree under `Graphite/aggregations/*/flights/frontend_client`.
export const GRAPHITE_CLIENT_METRICS_NAMESPACE = "flights.frontend_client";
export const ORDER_TOKEN_VALIDITY = 86400; // seconds in 24hrs
export { PARAM_LIST_SEPARATOR, MULTISTOP_IATA_LIST_SEPARATOR, URL_IATA_LIST_SEPARATOR, LOCATION_IATA_CODE_AND_TYPE_SEPARATOR, MANDATORY_SEARCH_PARAMS_ONEWAY };
export const SPACER = "·";
export const SPACER_PADDED = ` ${SPACER} `;
export const DASH_SPACER = "-";
export const DASH_SPACER_PADDED = ` ${DASH_SPACER} `;
export { CLIENT_SIDE_FEATURES };
export const ANYWHERE = "Anywhere";
export const COMPANY_NAME = "Booking.com";
export const IMAGE_STATIC_ASSETS_URL = "https://q-xx.bstatic.com";
export const PLATFORM_ENV = process.env.PLATFORM_ENV;
export const STAGING_HOSTNAME = "flights-staging.prod.booking.com";
export const HELP_CENTER_URL = "https://secure.booking.com/help";
// eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
export const FLIGHTS_BASE_URL = (req) => {
    if (!req)
        return;
    if (process.env.NODE_ENV === "development") {
        const protocol = Boolean(process.env.ENABLE_SSL) ? "https" : "http";
        // For IAM whitelist, we need to setup a localhost alias to a whitelisted url like https://flights-local.dev.booking.com
        // @see https://gitlab.booking.com/flights/main/-/wikis/Frontend/Architecture/Server#note-testing-sso-on-localhost
        return `${protocol}://${req.hostname}:${Number(process.env.PORT)}`;
    }
    switch (PLATFORM_ENV) {
        case "prod":
            return req.hostname === STAGING_HOSTNAME ? `https://${STAGING_HOSTNAME}` : "https://flights.booking.com";
        case "dqs":
            return "https://flights.dqs.booking.com";
        default:
            return `https://${req.hostname}`;
    }
};
// eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
export const FLIGHTS_OAUTH_CALLBACK_BASE_URL = (req) => `${FLIGHTS_BASE_URL(req)}/api/oauth`;
// eslint-disable-next-line @typescript-eslint/naming-convention -- this line was auto generated, hence fix the issue timely
export const ACCOUNTS_PORTAL_CALLBACK_PATH = (req) => {
    return FLIGHTS_OAUTH_CALLBACK_BASE_URL(req) + "/callback/v3";
};
export const LEGAL_LINKED_TRAVEL_ARRANGEMENT_COUNTRIES = [
    "at",
    "be",
    "bg",
    "ch",
    "cy",
    "cz",
    "de",
    "dk",
    "ee",
    "es",
    "fi",
    "fr",
    "gb",
    "gr",
    "hr",
    "hu",
    "ie",
    "is",
    "it",
    "li",
    "lt",
    "lu",
    "lv",
    "mt",
    "nl",
    "no",
    "pl",
    "pt",
    "ro",
    "se",
    "si",
    "sk"
];
export const ORDER_CONSISTENCY_TRANSFORM = {
    CONSISTENT: "CONSISTENT",
    INCONSISTENT: "INCONSISTENT"
};
export const CO2E_EMISSIONS_HC_LINK = `${HELP_CENTER_URL}/faq/40/RmxpZ2h0cyAtIEZBUXMgLSBDTzIgMTA5ODcw?category=flights_faq_topic_booking&render_html=1`;
