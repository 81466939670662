import { createSelector } from "@reduxjs/toolkit";
import { StoreState } from "store/index";

export const getSearchSegments = (state: StoreState) => state.search.searchSegments;

export const getSearchType = (state: StoreState) => state.search.type;

export const getNoOfAdults = (state: StoreState) => state.search.adults;

export const getNoOfChildren = (state: StoreState) => state.search.children.length;

export const getArrivalPlace = createSelector(
  (state: StoreState) => state.search.searchSegments[0]?.to,
  (arrivalSegments) => {
    const arrivalPlace = arrivalSegments?.[arrivalSegments?.length - 1];
    return { code: arrivalPlace?.code, type: arrivalPlace?.type };
  }
);

export const getLastDepartureDate = (state: StoreState) =>
  state.search.type === "ROUNDTRIP"
    ? state.search.searchSegments?.[state.search.searchSegments.length - 1]?.returnDate
    : state.search.searchSegments[0]?.departureDate;

export const getArrivalCountryCode = (state: StoreState) =>
  state.search.searchSegments[0]?.to?.[state.search.searchSegments[0]?.to?.length - 1]?.country;
