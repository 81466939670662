import loadable from "@loadable/component";
import LiveChat from "../screens/LiveChat";
import LiveChatEnd from "../screens/LiveChatEnd";

export const PostBookingSeatMap = loadable(() => import("../screens/PostBookingSeatMap"));
export const PostBookingSeatMapDesktop = loadable(() => import("../screens/PostBookingSeatMap.desktop"));
export const Home = loadable(() => import("../screens/Home"));
export const Search = loadable(() => import("../screens/Search"));
export const Landing = loadable(() => import("../screens/Landing"));
export const LandingDesktop = loadable(() => import("../screens/Landing.desktop"));
export const PostBookingFlightDetails = loadable(() => import("../screens/PostBookingFlightDetails"));
export const FlightDetails = loadable(() => import("../screens/FlightDetails"));
export const CheckoutFlightDetails = loadable(() => import("../screens/CheckoutFlightDetails"));
export const CheckoutFlightDetailsDesktop = loadable(() => import("../screens/CheckoutFlightDetails.desktop"));
export const CheckoutStart = loadable(() => import("../screens/CheckoutStart"));
export const CheckoutTicketType = loadable(() => import("../screens/CheckoutTicketType"));
export const CheckoutFare = loadable(() => import("../screens/CheckoutFare"));
export const CheckoutPax = loadable(() => import("../screens/CheckoutPax"));
export const CheckoutExtras = loadable(() => import("../screens/CheckoutExtras"));
export const CheckoutSeatMap = loadable(() => import("../screens/CheckoutSeatMap"));
export const CheckoutSirf = loadable(() => import("../screens/CheckoutSirf"));
export const CheckoutPayment = loadable(() => import("../screens/CheckoutPayment"));
export const CheckoutPriceChange = loadable(() => import("../screens/CheckoutPriceChange"));
export const Confirmation = loadable(() => import("../screens/Confirmation"));
export const ConfirmationDesktop = loadable(() => import("../screens/Confirmation.desktop"));
export const OrderDetails = loadable(() => import("../screens/OrderDetails"));
export const OrderDetailsDesktop = loadable(() => import("../screens/OrderDetails.desktop"));
export const ChangeFlight = loadable(() => import("../screens/ChangeFlight"));
export const ChangeFlightDesktop = loadable(() => import("../screens/ChangeFlight.desktop"));
export const FindMyBooking = loadable(() => import("../screens/FindMyBooking"));
export const FindMyBookingDesktop = loadable(() => import("../screens/FindMyBooking.desktop"));
export const PostBookingAddBaggage = loadable(() => import("../screens/PostBookingAddBaggage"));
export const PostBookingAddFastTrack = loadable(() => import("../screens/PostBookingAddFastTrack"));
export const PostBookingFastTrackConfirmation = loadable(() => import("../screens/PostBookingFastTrackConfirmation"));
export const PostBookingAddBaggageDesktop = loadable(() => import("../screens/PostBookingAddBaggage.desktop"));
export const HomeDesktop = loadable(() => import("../screens/Home.desktop"));
export const SearchDesktop = loadable(() => import("../screens/Search.desktop"));
export const FlightDetailsDesktop = loadable(() => import("../screens/FlightDetails.desktop"));
export const CheckoutStartDesktop = loadable(() => import("../screens/CheckoutStart.desktop"));
export const CheckoutTicketTypeDesktop = loadable(() => import("../screens/CheckoutTicketType.desktop"));
export const CheckoutFareDesktop = loadable(() => import("../screens/CheckoutFare.desktop"));
export const CheckoutPaxDesktop = loadable(() => import("../screens/CheckoutPax.desktop"));
export const CheckoutExtrasDesktop = loadable(() => import("../screens/CheckoutExtras.desktop"));
export const CheckoutSeatMapDesktop = loadable(() => import("../screens/CheckoutSeatMap.desktop"));
export const CheckoutSirfDesktop = loadable(() => import("../screens/CheckoutSirf.desktop"));
export const CheckoutPaymentDesktop = loadable(() => import("../screens/CheckoutPayment.desktop"));
export const CheckoutPriceChangeDesktop = loadable(() => import("../screens/CheckoutPriceChange.desktop"));
export const PostBookingPayment = loadable(() => import("../screens/PostBookingPayment"));
export const PostBookingPaymentDesktop = loadable(() => import("../screens/PostBookingPayment.desktop"));
export const PostBookingConfirmation = loadable(() => import("../screens/PostBookingConfirmation"));
export const PostBookingConfirmationDesktop = loadable(() => import("../screens/PostBookingConfirmation.desktop"));
export const PostBookingCancelOrder = loadable(() => import("../screens/PostBookingCancelOrder"));
export const PostBookingCancelOrderDesktop = loadable(() => import("../screens/PostBookingCancelOrder.desktop"));
export const AdditionalFlightsTerms = loadable(() => import("../screens/AdditionalFlightsTerms"));
export const WebCheckin = loadable(() => import("../screens/WebCheckin"));
export const WebCheckinDesktop = loadable(() => import("../screens/WebCheckin.desktop"));
export const WebCheckinV2 = loadable(() => import("../screens/WebCheckinV2"));
export const WebCheckinDesktopV2 = loadable(() => import("../screens/WebCheckinV2.desktop"));
export const NotAvailable = loadable(() => import("../screens/NotAvailable"));
export const CheckoutPaymentCollection = loadable(() => import("../screens/CheckoutPaymentCollection"));
export const CheckoutPaymentCollectionDesktop = loadable(() => import("../screens/CheckoutPaymentCollection.desktop"));
export const NotFound = loadable(() => import("../screens/NotFound"));
export const NotFoundDesktop = loadable(() => import("../screens/NotFound.desktop"));
export const FlyAnywhere = loadable(() => import("../screens/FlyAnywhere/FlyAnywhere"));
export const FlyAnywhereDesktop = loadable(() => import("../screens/FlyAnywhere/FlyAnywhere.desktop"));
export const CheckoutAlternativeFares = loadable(() => import("../screens/CheckoutAlternativeFares"));
export const CheckoutAlternativeFaresDesktop = loadable(() => import("../screens/CheckoutAlternativeFares.desktop"));

export type RouteName =
  | "home"
  | "searchresults"
  | "flightdetails"
  | "checkout-start"
  | "checkout-ticket-type" /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
  // TODO(asamilyak): rename to `checkout-pax` all over the code base.
  // Note these route names are already leaking to external systems (e.g. tracking and monitoring).
  | "checkout-fare"
  | "checkout" /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
  // TODO(asamilyak): rename to `checkout-extras`
  | "checkout2"
  | "checkout-seat-selection"
  | "checkout-sirf" /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
  // TODO(asamilyak): rename to `checkout-payment`
  | "checkout3"
  | "checkout-collection" // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  | "confirmation"
  | "pb-order-details"
  | "pb-change-flight"
  | "pb-find-order"
  | "pb-flight-details"
  | "pb-add-baggage"
  | "pb-add-fast-track"
  | "pb-add-fast-track-confirmation"
  | "pricechange"
  | "pb-checkout-payment"
  | "pb-checkout-confirmation"
  | "additional-flights-terms"
  | "pb-cancel-order"
  | "autocomplete-test"
  | "pb-seatmap"
  | "pb-web-checkin"
  | "pb-web-checkin-v2"
  | "not-available"
  | "landing"
  | "live-chat"
  | "live-chat-end"
  | "not-found"
  | "fly-anywhere"
  | "alternative-fares";

export type Route = {
  path: string;
  component: any;
  exclude?: boolean;
  name: RouteName;
  exact?: boolean;
};

const FLIGHT_DETAILS_ROUTE_PATH = "/flights/:airports/:id";
const SEARCH_RESULTS_ROUTE_PATH = "/flights/:airports";
const CHECKOUT_START_ROUTE_PATH = "/checkout/start/:flightToken";

const routeMap = function (props: { isMobile?: boolean; isPaymentCollectionPage?: boolean }) {
  const { isMobile, isPaymentCollectionPage } = props;
  const routes: Route[] = [
    {
      path: "/flights/route/:slug.:language?.html",
      component: isMobile ? Landing : LandingDesktop,
      name: "landing",
      exact: true
    },
    {
      path: "/flights/index.:language?.html",
      component: isMobile ? Home : HomeDesktop,
      name: "home",
      exact: true
    },
    {
      path: "/",
      component: isMobile ? Home : HomeDesktop,
      name: "home",
      exact: true
    },
    {
      /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
      /*
        In APM payment scenario checkout-payment screen (aka checkout3) is visited by `/checkout3/:orderToken`
        deprecated url. This is when user gets back to our checkout from external payment system. In this scenario
        we check order status and redirect further to confirmation.

        `/checkout3/:orderToken` url is used by our BE to redirect back, it's hardcoded in our java layer code base.

        TODO(asamilyak): consider moving this logic to a separate technical checkout step, e.g. `/checkout/finish`
           (as we already have `/checkout/start`) and migrate our BE to it.
      */
      path: "/checkout3/:orderToken",
      component: isMobile ? CheckoutPayment : CheckoutPaymentDesktop,
      name: "checkout3",
      exact: true
    },
    {
      /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
      /*  Once apps native checkout is launched this route would have traffic only from older versions of apps.
          Server redirect `/checkout/:flightToken` --> `/checkout/start/:flightToken` is also in place,
          but believe it or not webview doesn't follow server redirects by default (which is the case in our apps).
          TODO(asamilyak): add visits tracking on this route after native checkout launch
            and remove it after 01-01-2021 in case of zero/low volume (this is reasonable time for legacy apps support).
      */
      path: "/checkout/:flightToken",
      component: CheckoutStart,
      name: "checkout-start",
      exact: true
    },
    {
      path: CHECKOUT_START_ROUTE_PATH,
      component: isMobile ? CheckoutStart : CheckoutStartDesktop,
      name: "checkout-start",
      exact: true
    },
    {
      path: "/checkout/ticket-type/:flightToken/:cartToken",
      component: isMobile ? CheckoutTicketType : CheckoutTicketTypeDesktop,
      name: "checkout-ticket-type",
      exact: true
    },
    {
      path: "/checkout/fare/:flightToken/:cartToken",
      component: isMobile ? CheckoutFare : CheckoutFareDesktop,
      name: "checkout-fare",
      exact: true
    },
    {
      // This path is used to prevent double cart creation for branded fares
      path: "/checkout/fare/:flightToken",
      component: isMobile ? CheckoutFare : CheckoutFareDesktop,
      name: "checkout-fare",
      exact: true
    },
    {
      path: "/checkout/pax/:flightToken/:cartToken",
      component: isMobile ? CheckoutPax : CheckoutPaxDesktop,
      name: "checkout",
      exact: true
    },
    {
      path: "/checkout/extras/:flightToken/:cartToken",
      component: isMobile ? CheckoutExtras : CheckoutExtrasDesktop,
      name: "checkout2",
      exact: true
    },
    {
      path: "/checkout/seats/:flightToken/:cartToken",
      component: isMobile ? CheckoutSeatMap : CheckoutSeatMapDesktop,
      name: "checkout-seat-selection",
      exact: true
    },
    {
      // SIRF = Spanish islands resident fare
      path: "/checkout/sirf/:flightToken/:cartToken",
      component: isMobile ? CheckoutSirf : CheckoutSirfDesktop,
      name: "checkout-sirf",
      exact: true
    },
    {
      path: "/checkout/payment/:flightToken/:orderToken",
      component: isMobile ? CheckoutPayment : CheckoutPaymentDesktop,
      name: "checkout3",
      exact: true
    },
    {
      path: "/checkout/pricechange/:orderToken",
      component: isMobile ? CheckoutPriceChange : CheckoutPriceChangeDesktop,
      name: "pricechange",
      exact: true
    },
    {
      path: "/checkout/alternativeFares/:flightToken",
      component: isMobile ? CheckoutAlternativeFares : CheckoutAlternativeFaresDesktop,
      name: "alternative-fares",
      exact: true
    },
    {
      path: "/booking/confirmation/:token",
      component: isMobile ? Confirmation : ConfirmationDesktop,
      name: "confirmation",
      exact: true
    },
    {
      path: "/booking/order-details/:token",
      component: isMobile ? OrderDetails : OrderDetailsDesktop,
      name: "pb-order-details",
      exact: true
    },
    {
      path: "/booking/order-details/:token/change-flight",
      component: isMobile ? ChangeFlight : ChangeFlightDesktop,
      name: "pb-change-flight",
      exact: true
    },
    {
      path: "/mybooking",
      component: isMobile ? FindMyBooking : FindMyBookingDesktop,
      name: "pb-find-order",
      exact: true
    },
    {
      path: "/booking/cancel/:orderToken",
      component: isMobile ? PostBookingCancelOrder : PostBookingCancelOrderDesktop,
      name: "pb-cancel-order",
      exact: true
    },
    {
      path: "/booking/flight-details/:token/:bound",
      component: isMobile ? PostBookingFlightDetails : OrderDetailsDesktop, // while on Desktop, the FlightDetails content is displayed on a Modal over OrderDetails
      name: "pb-flight-details",
      exact: true
    },
    {
      path: "/booking/add-baggage/:token",
      component: isMobile ? PostBookingAddBaggage : PostBookingAddBaggageDesktop,
      name: "pb-add-baggage",
      exact: true
    },
    {
      path: "/booking/add-fast-track/:token",
      component: PostBookingAddFastTrack,
      name: "pb-add-fast-track",
      exact: true
    },
    {
      path: "/booking/add-fast-track/:token/confirmation",
      component: PostBookingFastTrackConfirmation,
      name: "pb-add-fast-track-confirmation",
      exact: true
    },
    {
      path: "/booking/checkout/:token/payment",
      component: isMobile ? PostBookingPayment : PostBookingPaymentDesktop,
      name: "pb-checkout-payment",
      exact: true
    },
    {
      path: "/booking/checkout/:token/confirmation",
      component: isMobile ? PostBookingConfirmation : PostBookingConfirmationDesktop,
      name: "pb-checkout-confirmation",
      exact: true
    },
    {
      path: "/booking/add-seats/:orderToken",
      component: isMobile ? PostBookingSeatMap : PostBookingSeatMapDesktop,
      name: "pb-seatmap",
      exact: true
    },
    {
      path: "/additional-flights-terms",
      component: AdditionalFlightsTerms,
      name: "additional-flights-terms",
      exact: true
    },
    {
      path: "/booking/webcheckin/:orderToken",
      component: isMobile ? WebCheckin : WebCheckinDesktop,
      name: "pb-web-checkin",
      exact: true
    },
    {
      path: "/booking/webcheckin/:orderToken/:segmentIndex",
      component: isMobile ? WebCheckinV2 : WebCheckinDesktopV2,
      name: "pb-web-checkin-v2",
      exact: true
    },
    {
      path: "/not-available",
      component: NotAvailable,
      name: "not-available",
      exact: true
    },
    {
      path: "/live-chat/orderToken/:token",
      component: LiveChat,
      name: "live-chat",
      exact: true
    },
    {
      path: "/live-chat/ended",
      component: LiveChatEnd,
      name: "live-chat-end",
      exact: true
    },
    {
      path: "/not-found",
      component: isMobile ? NotFound : NotFoundDesktop,
      name: "not-found",
      exact: true
    },
    {
      path: "/fly-anywhere",
      component: isMobile ? FlyAnywhere : FlyAnywhereDesktop,
      name: "fly-anywhere",
      exact: true
    }
  ];

  if (isPaymentCollectionPage) {
    routes.push({
      // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
      path: "/checkout-collection/:orderToken",
      component: isMobile ? CheckoutPaymentCollection : CheckoutPaymentCollectionDesktop,
      name: "checkout-collection",
      exact: true
    });
  }

  if (isMobile) {
    routes.push({
      path: FLIGHT_DETAILS_ROUTE_PATH,
      component: FlightDetails,
      name: "flightdetails",
      exact: true
    });
    routes.push({
      path: SEARCH_RESULTS_ROUTE_PATH,
      component: Search,
      name: "searchresults",
      exact: true
    });
  } else {
    routes.push({
      path: FLIGHT_DETAILS_ROUTE_PATH,
      component: FlightDetailsDesktop,
      name: "flightdetails",
      exclude: true, // Won't show up in Layout component
      exact: true
    });
    routes.push({
      path: SEARCH_RESULTS_ROUTE_PATH,
      component: SearchDesktop,
      name: "searchresults",
      exact: false
    });
  }

  return routes;
};

const doesRouteNameExist = function (props: {
  routeName: string;
  isMobile: boolean;
  isPaymentCollectionPage: boolean;
}): boolean {
  const { isMobile, routeName, isPaymentCollectionPage } = props;
  return routeMap({ isMobile, isPaymentCollectionPage }).some((route) => route.name === routeName);
};

export default routeMap;
export { doesRouteNameExist, FLIGHT_DETAILS_ROUTE_PATH, SEARCH_RESULTS_ROUTE_PATH, CHECKOUT_START_ROUTE_PATH };
