import React, { useEffect, useRef, useState } from "react";
import { UIAvailableExtraProducts, UIBrandedFareOrFlexTicket, UIFetchStatus, UIFlightData } from "@flights/types";
import { Button, Card, Popover, SkeletonLoader, Stack, Text, useTheme } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { lowerCase, startCase } from "lodash";
import { mcn } from "utils/mergeClassnames";
import useGoToFlightDetails from "components/elements/FareSelector/hooks/useGoToFlightDetails";
import Frame from "components/elements/Frame";
import FareFeatures from "components/elements/FareFeatures";
import FareFeaturesIcons from "components/elements/FareFeatureIcons";
import FareCardPrice from "./FareCardPrice";
import styles from "./FareCard.desktop.module.css";
import flights_apex_web_expand_bf_on_sr from "utils/experiments/apex/flights_apex_web_expand_bf_on_sr";
import { areSellableFeaturesRequiredByAirline } from "@flights/ancillaries";

type FareCardProps = {
  fareOffer: UIBrandedFareOrFlexTicket;
  baseOffer: UIFlightData;
  loadingStatus?: UIFetchStatus;
  index?: number;
  numberOfOffers?: number;
  ancillaries?: UIAvailableExtraProducts;
  brandedFaresShownByDefault?: boolean;
};

const FareCardDesktop = ({
  baseOffer,
  fareOffer,
  loadingStatus,
  index,
  numberOfOffers,
  brandedFaresShownByDefault
}: FareCardProps) => {
  const i18n = useI18n();
  const theme = useTheme();
  const popoverRef = useRef(document.body);
  const goToFlightDetails = useGoToFlightDetails();
  const showExpandedBf = !brandedFaresShownByDefault && !!flights_apex_web_expand_bf_on_sr.trackWithDefaultStage();
  const sellableFeaturesRequiredByAirline = areSellableFeaturesRequiredByAirline(baseOffer.segments);
  const [popoverShown, setPopoverShown] = useState(false);

  const { brandedFareInfo, includedProducts, isFlexTicket, ancillaries } = fareOffer;

  useEffect(() => {
    if (loadingStatus === "success" && numberOfOffers === undefined && !brandedFaresShownByDefault)
      flights_apex_web_expand_bf_on_sr.stages.false_positive();
  }, [brandedFaresShownByDefault, loadingStatus, numberOfOffers]);

  const renderedFeatures = (
    <FareFeatures
      brandedFareInfo={brandedFareInfo}
      includedProducts={includedProducts}
      ancillaries={ancillaries}
      sellableFeaturesRequiredByAirline={sellableFeaturesRequiredByAirline}
      showHeader={true}
      isPreCheckAndPay={true}
      areShown={popoverShown}
    />
  );

  return (
    <Popover
      triggerType="hover"
      containerRef={popoverRef}
      onOpen={() => {
        flights_apex_web_expand_bf_on_sr.goals.sees_fare_details();
        setPopoverShown(true);
      }}
      onClose={() => {
        setPopoverShown(false);
      }}
    >
      <Popover.Trigger className={styles.popoverTrigger}>
        {(attributes) => (
          <Card className={styles.card}>
            <div>
              <Stack
                attributes={{
                  ...attributes,
                  tabIndex: 0
                }}
                justifyContent="space-between"
              >
                <Text
                  className={!showExpandedBf && !brandedFaresShownByDefault && styles.title}
                  variant={showExpandedBf || brandedFaresShownByDefault ? "strong_2" : "strong_1"}
                  attributes={{ "data-fare-card-row": "title" }}
                >
                  {loadingStatus === "loading" ? (
                    <Stack gap={6} direction="row">
                      <Stack.Item grow>
                        <SkeletonLoader variant={showExpandedBf || brandedFaresShownByDefault ? "title" : undefined} />
                      </Stack.Item>
                      <SkeletonLoader width={theme.units.spacing_12x} />
                    </Stack>
                  ) : (
                    startCase(lowerCase(brandedFareInfo?.fareName))
                  )}
                </Text>
                <div>
                  <Frame
                    pb={2}
                    className={!showExpandedBf && !brandedFaresShownByDefault && styles.fareFeaturesIcons}
                    data-fare-card-row="icons"
                  >
                    <FareFeaturesIcons
                      includedProducts={includedProducts}
                      brandedFareInfo={brandedFareInfo}
                      limit={7}
                      isLarge={!brandedFaresShownByDefault && !showExpandedBf}
                      isCompact={true}
                      isPreCheckAndPay={true}
                    />
                  </Frame>
                  <FareCardPrice
                    fareOffer={fareOffer}
                    baseOffer={baseOffer}
                    isFlexTicket={isFlexTicket}
                    brandedFaresShownByDefault={brandedFaresShownByDefault}
                  />
                  {renderedFeatures && (
                    <div id="features-aria" className={styles.ariaInvisible}>
                      {renderedFeatures}
                    </div>
                  )}
                </div>
              </Stack>
              <Button
                onClick={() => {
                  if (index === 0) flights_apex_web_expand_bf_on_sr.goals.clicks_lowest_fare();
                  if (index && index > 0 && !brandedFaresShownByDefault)
                    flights_apex_web_expand_bf_on_sr.stages.selects_upsell_fare();
                  goToFlightDetails(fareOffer.token, baseOffer, isFlexTicket);
                }}
                className={mcn(styles.button, (showExpandedBf || brandedFaresShownByDefault) && styles.btnPadding)}
                variant="secondary"
                attributes={{
                  ...(index !== undefined && {
                    "aria-label": `${index + 1} of ${numberOfOffers}`
                  })
                }}
              >
                {i18n.trans(t("flights_apex_bf_sr_bf_card_cta"))}
              </Button>
            </div>
          </Card>
        )}
      </Popover.Trigger>
      {renderedFeatures ? (
        <Popover.Content
          attributes={{
            style: {
              width: 240,
              whiteSpace: "pre-wrap",
              overflow: "hidden"
            }
          }}
        >
          {renderedFeatures}
        </Popover.Content>
      ) : null}
    </Popover>
  );
};

export default FareCardDesktop;
