/**
 * =============================================================================
 * this component will be moved to searchbox package (if/when full on)
 * =============================================================================
 */

import React, { Fragment, useMemo } from "react";
import { Actionable, Icon, Stack, Text } from "@bookingcom/bui-react";
import {
  ArrowsLeftRightIcon,
  MagnifyingGlassIcon,
  ArrowLongRightIcon
} from "@bookingcom/bui-assets-react/streamline/index.js";
import style from "./SearchOverview.module.css";
import useSearchOverview from "./useSearchOverview";
import { trackExperimentStage } from "utils/et";
import { useStickySearchBoxExp } from "../../../utils/experiments/mdotvalue/flights_mdotvalue_sr_sticky_search_box";
import { mcn } from "utils/mergeClassnames";
import useRouteName from "hooks/useRouteName";
import useUserAgent from "hooks/useUserAgent";

export default function SearchOverview() {
  const { handleOnClick, type, airportList, travellers, cabin, dates, ariaLabel } = useSearchOverview();
  const { getVariant } = useStickySearchBoxExp();
  const { isMobile } = useUserAgent();
  const routeName = useRouteName();

  const arrow = useMemo(() => {
    switch (type) {
      case "ROUNDTRIP":
        return <Icon className={style.arrow} svg={ArrowsLeftRightIcon} />;
      default:
        return <Icon className={style.arrow} svg={ArrowLongRightIcon} />;
    }
  }, [type]);

  return (
    <div
      className={mcn(
        style.root,
        getVariant() ? style.searchOverviewSticky : "" //@exp flights_mdotvalue_sr_sticky_search_box
      )}
    >
      <div className={style.blue} />
      <Actionable
        type="button"
        onClick={() => {
          if (routeName === "searchresults" && isMobile) {
            trackExperimentStage("flights_web_q4_blackout", 1);
            trackExperimentStage("flights_web_q4_blackout", 3);
            trackExperimentStage("flights_web_q4_blackout", 4);
          }
          handleOnClick();
        }}
        attributes={{ "aria-label": ariaLabel, style: { display: "block" } }}
        className={style.outer}
      >
        <div className={style.inner}>
          <Stack direction="row" alignItems="center" gap={2} className={style.w100}>
            <Icon svg={MagnifyingGlassIcon} size="medium" />
            <Stack.Item grow className={style.widthFix}>
              <Stack direction="row" alignItems="center" gap={1} className={style.w100}>
                <Text tagName="span" variant="strong_2" className={style.ellipsis}>
                  {airportList.map((fromTo, i) => (
                    <Fragment key={fromTo.toString() + i}>
                      {i !== 0 && " · "}
                      {fromTo.map((_, idx) => (
                        <Fragment key={_.toString() + idx}>
                          {idx !== 0 && " "}
                          {idx !== 0 && arrow}
                          {idx !== 0 && " "}
                          <span>{_}</span>
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </Text>
              </Stack>
              <Text tagName="span" variant="small_1" className={style.ellipsis}>
                {[dates, travellers, cabin].map((_, idx) => `${idx !== 0 ? " · " : ""}${_}`).join("")}
              </Text>
            </Stack.Item>
          </Stack>
        </div>
      </Actionable>
    </div>
  );
}
