import { ActionCreatorsMapObject, FunctionType } from "@bookingcom/flights-core";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";

import * as search from "./search/reducer";
import * as searchResults from "./searchResults/reducer";
import * as flexibleDateMinPrice from "./flexibleDateMinPrice/reducer";
import * as searchCriteria from "./searchCriteria/reducer";
import * as flightDetails from "./flightDetails/reducer";
import * as cartDetails from "./cartDetails/reducer";
import * as sanctionScreening from "./sanctionScreening/reducer";
import * as extraProducts from "./extraProducts/reducer";
import * as loadingScreen from "./loadingScreen/reducer";
import * as order from "./order/reducer";
import * as user from "./user/reducer";
import * as crossSell from "./crossSell/reducer";
import * as cheapFlights from "./cheapFlights/reducer";
import * as trendingDestinations from "./trendingDestinations/reducer";
import * as worldwideFlights from "./worldwideFlights/reducer";
import * as flightsThemes from "./flightsThemes/reducer";
import * as routeInfo from "./routeInfo/reducer";
import * as ariaLive from "./ariaLive/reducer";
import * as metaFlightDetails from "./metaFlightDetails/reducer";
import * as travelPurpose from "./travelPurpose/reducer";
import * as brandedFares from "./brandedFareOffers/reducer";
import * as rebookPolicy from "./rebookPolicy/reducer";
import * as paymentCollection from "./paymentCollection/reducer"; // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
import * as indexCampaigns from "./indexCampaigns/reducer";
import * as cartValidationError from "./cartValidationError/reducer";
import * as flyAnywhere from "./flyAnywhere/reducer";
import * as flightsInterlinking from "./flightsInterlinking/reducer";
import * as esim from "./esim/reducer";
import * as weekendBreaks from "./weekendBreaks/reducer";
import { listenerMiddleware } from "./middlewares";

declare var window: { __INITIAL_STATE__: any };

export const getInitialState = () => ({
  search: search.getInitialState(),
  searchResults: searchResults.getInitialState(),
  flexibleDateMinPrice: flexibleDateMinPrice.getInitialState(),
  searchCriteria: searchCriteria.getInitialState(),
  flightDetails: flightDetails.getInitialState(),
  cartDetails: cartDetails.getInitialState(),
  sanctionScreening: sanctionScreening.getInitialState(),
  extraProducts: extraProducts.getInitialState(),
  order: order.getInitialState(),
  loadingScreen: loadingScreen.getInitialState(),
  user: user.getInitialState(),
  crossSell: crossSell.getInitialState(),
  cheapFlights: cheapFlights.getInitialState(),
  trendingDestinations: trendingDestinations.getInitialState(),
  worldwideFlights: worldwideFlights.getInitialState(),
  flightsThemes: flightsThemes.getInitialState(),
  routeInfo: routeInfo.getInitialState(),
  ariaLive: ariaLive.getInitialState(),
  metaFlightDetails: metaFlightDetails.getInitialState(),
  travelPurpose: travelPurpose.getInitialState(),
  brandedFares: brandedFares.getInitialState(),
  paymentCollection: paymentCollection.getInitialState(), // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  rebookPolicy: rebookPolicy.getInitialState(),
  indexCampaigns: indexCampaigns.getInitialState(),
  cartValidationError: cartValidationError.getInitialState(),
  flyAnywhere: flyAnywhere.getInitialState(),
  flightsInterlinking: flightsInterlinking.getInitialState(),
  esim: esim.getInitialState(),
  weekendBreaks: weekendBreaks.getInitialState()
});

export type StoreState = ReturnType<typeof getInitialState>;

export function createStore(initialState: Partial<StoreState> = {}) {
  return configureStore({
    reducer: {
      search: search.reducer,
      searchResults: searchResults.reducer,
      flexibleDateMinPrice: flexibleDateMinPrice.reducer,
      searchCriteria: searchCriteria.reducer,
      flightDetails: flightDetails.reducer,
      cartDetails: cartDetails.reducer,
      sanctionScreening: sanctionScreening.reducer,
      extraProducts: extraProducts.reducer,
      order: order.reducer,
      loadingScreen: loadingScreen.reducer,
      user: user.reducer,
      crossSell: crossSell.reducer,
      cheapFlights: cheapFlights.reducer,
      trendingDestinations: trendingDestinations.reducer,
      worldwideFlights: worldwideFlights.reducer,
      flightsThemes: flightsThemes.reducer,
      routeInfo: routeInfo.reducer,
      ariaLive: ariaLive.reducer,
      metaFlightDetails: metaFlightDetails.reducer,
      travelPurpose: travelPurpose.reducer,
      brandedFares: brandedFares.reducer,
      paymentCollection: paymentCollection.reducer, // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
      rebookPolicy: rebookPolicy.reducer,
      indexCampaigns: indexCampaigns.reducer,
      cartValidationError: cartValidationError.reducer,
      flyAnywhere: flyAnywhere.reducer,
      flightsInterlinking: flightsInterlinking.reducer,
      esim: esim.reducer,
      weekendBreaks: weekendBreaks.reducer
    },
    devTools: {
      name: "Redux store - Main"
    },
    preloadedState: {
      search: search.getInitialState(),
      searchResults: searchResults.getInitialState(),
      flexibleDateMinPrice: flexibleDateMinPrice.getInitialState(),
      searchCriteria: searchCriteria.getInitialState(),
      flightDetails: flightDetails.getInitialState(),
      cartDetails: cartDetails.getInitialState(),
      sanctionScreening: sanctionScreening.getInitialState(),
      extraProducts: extraProducts.getInitialState(),
      order: order.getInitialState(),
      loadingScreen: loadingScreen.getInitialState(),
      user: user.getInitialState(),
      crossSell: crossSell.getInitialState(),
      cheapFlights: cheapFlights.getInitialState(),
      trendingDestinations: trendingDestinations.getInitialState(),
      worldwideFlights: worldwideFlights.getInitialState(),
      flightsThemes: flightsThemes.getInitialState(),
      routeInfo: routeInfo.getInitialState(),
      ariaLive: ariaLive.getInitialState(),
      metaFlightDetails: metaFlightDetails.getInitialState(),
      travelPurpose: travelPurpose.getInitialState(),
      brandedFares: brandedFares.getInitialState(),
      paymentCollection: paymentCollection.getInitialState(), // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
      rebookPolicy: rebookPolicy.getInitialState(),
      indexCampaigns: indexCampaigns.getInitialState(),
      cartValidationError: cartValidationError.getInitialState(),
      flyAnywhere: flyAnywhere.getInitialState(),
      flightsInterlinking: flightsInterlinking.getInitialState(),
      esim: esim.getInitialState(),
      weekendBreaks: weekendBreaks.getInitialState(),
      ...(process.env.BUILD_TARGET === "client" && window.__INITIAL_STATE__),
      ...initialState
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware)
  });
}

export type Store = ReturnType<typeof createStore>;
export type RootState = ReturnType<Store["getState"]>;

const getAll = (state: RootState) => state;

/**
 * @deprecated
 * The useStore hook is inefficient, since it will cause your component to re-render whenever ANYTHING in the store
 * changes. Please use the useSelector hook provided by react-redux
 * @see https://gitlab.booking.com/flights/main/-/wikis/Using-the-Redux-Store
 */
export const useStore = (): RootState => useSelector(getAll);

/**
 * @deprecated
 * The useActions hook was part of our old store.
 * Please use the useDispatch hook provided by react-redux
 * @see https://gitlab.booking.com/flights/main/-/wikis/Using-the-Redux-Store
 */
export const useActions = <A extends ActionCreatorsMapObject>(actions: A): A => {
  const dispatch = useDispatch();

  return useMemo(() => {
    const enhancedActions = {};
    Object.entries(actions).forEach(([key, action]) => {
      // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
      enhancedActions[key] = (...args: any[]) => dispatch(action(...args)) as FunctionType;
    });
    return enhancedActions as A;
  }, [dispatch, actions]);
};
