/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useState } from "react";
import { Actionable, Box, Button, Icon, Popover, SheetContainer, Stack, Text } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import useFormatPrice from "hooks/useFormatPrice";
import { UIOrder, UITripRebookOption } from "@flights/types";
import { RebookBreakdownList } from "./RebookBreakdownList";
import useUserAgent from "hooks/useUserAgent";
import { QuestionMarkCircleIcon } from "@bookingcom/bui-assets-react/streamline/index.js";

interface RebookCostBreakdownProps {
  breakdownOpen: boolean;
  setBreakdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tripRebookOption: UITripRebookOption;
  initialOrder: UIOrder;
  align: "start" | "end";
}

const resetBodyOverflow = () => {
  document.body.style.overflow = "auto";
};

export const RebookCostBreakdown: React.FC<RebookCostBreakdownProps> = ({
  breakdownOpen,
  setBreakdownOpen,
  tripRebookOption,
  initialOrder,
  align
}) => {
  const { formatPrice } = useFormatPrice();
  const { isMobile } = useUserAgent();
  const i18n = useI18n();
  const [showPopover, setShowPopover] = useState(false);
  const totalDifferenceForAllPassengers = formatPrice(tripRebookOption.rebookPrice.totalDifference);

  const handleBreakdownOpen = React.useCallback<(status: boolean) => void>(
    (status) => {
      setBreakdownOpen(status);
    },
    [setBreakdownOpen]
  );

  const handleInteraction = (e: React.SyntheticEvent, status: boolean) => {
    e.stopPropagation();
    handleBreakdownOpen(status);
    isMobile && resetBodyOverflow();
  };

  const priceBreakDownIcon = isMobile ? (
    <Actionable
      onClick={(e) => {
        handleInteraction(e, true);
      }}
      attributes={{
        "aria-label": i18n.trans(t("a11y_flights_pb_rebook_cost_breakdown_open_cta"))
      }}
    >
      <Box padding={2}>
        <Icon svg={QuestionMarkCircleIcon} />
      </Box>
    </Actionable>
  ) : (
    <Popover
      position="top-start"
      active={showPopover}
      onOpen={() => {
        setShowPopover(true);
      }}
      onClose={() => {
        setShowPopover(false);
      }}
      closeAriaLabel={i18n.trans(t("a11y_flights_pb_rebook_cost_breakdown_close_cta"))}
    >
      <Popover.Trigger>
        {(attributes) => (
          <Button
            variant="tertiary-neutral"
            size="medium"
            attributes={{
              "aria-label": i18n.trans(t("a11y_flights_pb_rebook_cost_breakdown_open_cta")),
              ...attributes
            }}
          >
            <Icon svg={QuestionMarkCircleIcon} />
          </Button>
        )}
      </Popover.Trigger>
      <Popover.Content>
        <Stack>
          <Text tagName="h4" variant="headline_3">
            {i18n.trans(t("flights_pb_rebook_cost_breakdown_header"))}
          </Text>
          <RebookBreakdownList
            totalDifferenceForAllPassengers={totalDifferenceForAllPassengers}
            initialOrder={initialOrder}
            tripRebookOption={tripRebookOption}
          />
        </Stack>
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <Stack gap={0} direction="column" alignItems={align}>
        <Stack direction="row" wrap="nowrap" gap={0}>
          <Box padding={1}>
            <Text variant="headline_3" tagName="h3">
              + {totalDifferenceForAllPassengers}
            </Text>
          </Box>
          {priceBreakDownIcon}
        </Stack>
        <Text color="neutral_alt">{i18n.trans(t("flights_pb_rebook_change_cost_label"))}</Text>
      </Stack>
      {isMobile && breakdownOpen && (
        <SheetContainer
          onCloseTrigger={() => setBreakdownOpen(false)}
          title={i18n.trans(t("flights_pb_rebook_cost_breakdown_header"))}
          closeAriaLabel={i18n.trans(t("a11y_flights_pb_rebook_cost_breakdown_close_cta"))}
          active
        >
          <RebookBreakdownList
            totalDifferenceForAllPassengers={totalDifferenceForAllPassengers}
            initialOrder={initialOrder}
            tripRebookOption={tripRebookOption}
          />
        </SheetContainer>
      )}
    </>
  );
};
