import { UIProductSelection } from "@flights/types";
import { getOfferCheckoutData, setOfferCheckoutData } from "./checkoutOfferSessionStorage";

export default {
  add: <T extends keyof UIProductSelection>({
    offerToken,
    key,
    value
  }: {
    offerToken: string;
    key: T;
    value: UIProductSelection[T];
  }) => {
    setOfferCheckoutData(offerToken, (prevState) => {
      if (prevState.ancillaries) {
        prevState.ancillaries[key] = value;
      } else {
        prevState.ancillaries = { [key]: value };
      }

      return prevState;
    });
  },
  remove: <T extends keyof UIProductSelection>({ offerToken, key }: { offerToken: string; key: T }) => {
    setOfferCheckoutData(offerToken, (prevState) => {
      if (prevState.ancillaries) {
        delete prevState.ancillaries[key];
      }

      return prevState;
    });
  },
  get: (offerToken: string) => getOfferCheckoutData(offerToken)
};
