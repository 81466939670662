/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useCallback, useEffect, useState } from "react";
import { Banner } from "@bookingcom/bui-react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useGlobalContext from "hooks/useGlobalContext";
import { setCookie, getCookie } from "utils/cookies";
import Frame from "../Frame";
import useRouteName from "hooks/useRouteName";

export default function DSABanner() {
  const routeName = useRouteName();
  const i18n = useI18n();
  const { contentStandardsGuidelines } = useGlobalContext();
  const [visible, setVisibility] = useState(false);

  const cookieName = routeName === "fly-anywhere" ? "hide_banner_DSA_fa" : "hide_banner_DSA";

  const handleOnClose = useCallback(() => {
    setVisibility(false);
    try {
      setCookie(cookieName, "1", { maxAge: "1y" });
    } catch (error) {} // eslint-disable-line flights/no-empty-catch
  }, [cookieName]);

  useEffect(() => {
    try {
      setVisibility(!getCookie(cookieName));
    } catch (error) {} // eslint-disable-line flights/no-empty-catch
  }, [cookieName]);

  if (!contentStandardsGuidelines) return null;
  if (!visible) return null;

  return (
    <Frame mb={4} attributes={{ "data-testid": "DSABanner" }}>
      <Banner
        dismissible
        closeAriaLabel={i18n.trans(t("close"))}
        text={contentStandardsGuidelines.message}
        onClose={handleOnClose}
        attributes={{ style: { zIndex: 0, position: "relative" } }}
        actions={[
          {
            attributes: { target: "_blank", "data-testid": "DSABanner_link" },
            href: contentStandardsGuidelines.linkUrl,
            text: contentStandardsGuidelines.linkLabel
          }
        ]}
      />
    </Frame>
  );
}
