import usePointOfSale from "hooks/usePointOfSale";
import { useDispatch, useSelector } from "react-redux";
import { getArrivalPlace, getLastDepartureDate, getSearchType, getArrivalCountryCode } from "store/search/selectors";
import {
  getArrivalPlace as getArrivalPlaceFlight,
  getTripType as getTripTypeFlight,
  getArrivalCountry as getArrivalCountryFlight,
  getLastDepartureDate as getLastDepartureDateFlight
} from "store/flightDetails/selectors";
import { getGeniusLevel, getUserEmail } from "store/user/selectors";
import flights_ace_web_esim_banner from "utils/experiments/ace/flights_ace_web_esim_banner";
import { actions as esimActions } from "store/esim/actions";
import {
  getOrderId,
  getArrivalPlace as getArrivalPlaceOrder,
  getTripType as getTripTypeOrder,
  getLastDepartureDate as getLastDepartureDateOrder,
  getArrivalCountryCode as getArrivalCountryCodeOrder,
  getOrderStatus,
  getOrderBookedAt
} from "store/order/selectors";
import useContextName from "hooks/useContextName";
import { useCallback } from "react";
import clientFetch from "utils/client-fetch";
import { UIEsim } from "@flights/types";
import useGlobalContext from "hooks/useGlobalContext";
import { differenceInCalendarDays } from "date-fns";

export const useEsimSearch = () => {
  const dispatch = useDispatch();
  const { features } = useGlobalContext();
  const userEmail = useSelector(getUserEmail);
  const geniusLevel = useSelector(getGeniusLevel)?.toString();
  const arrivalPlace = useSelector(getArrivalPlace);
  const arrivalCountry = useSelector(getArrivalCountryCode);
  const tripType = useSelector(getSearchType);
  const lastDepartureDate = useSelector(getLastDepartureDate);
  const pointOfSale = usePointOfSale();
  const contextName = useContextName();
  const esimCheckFeature = !!features["FLIGHTS_ESIM_ELIGIBILTY_CHECK_WEB"];

  return useCallback(() => {
    const shouldPost = !!userEmail && esimCheckFeature && tripType && contextName === "search_results";
    if (!shouldPost) return;

    dispatch(esimActions.fetch());
    return clientFetch("/api/esimEligibility/check", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Flights-Context-Name": contextName
      },
      body: JSON.stringify({
        pointOfSale,
        geniusLevel,
        lastDepartureDate,
        arrivalCountry,
        tripType,
        arrivalPlaceType: arrivalPlace?.type,
        arrivalPlaceName: arrivalPlace?.code
      })
    })
      .then((data: UIEsim) => dispatch(esimActions.fetchSuccess(data)))
      .catch((error) => dispatch(esimActions.fetchError(error)));
  }, [
    esimCheckFeature,
    tripType,
    contextName,
    lastDepartureDate,
    arrivalPlace,
    arrivalCountry,
    geniusLevel,
    pointOfSale,
    dispatch,
    userEmail
  ]);
};

export const useEsimFlight = () => {
  const dispatch = useDispatch();
  const { features } = useGlobalContext();
  const userEmail = useSelector(getUserEmail);
  const geniusLevel = useSelector(getGeniusLevel)?.toString();
  const arrivalPlace = useSelector(getArrivalPlaceFlight);
  const arrivalCountry = useSelector(getArrivalCountryFlight);
  const tripType = useSelector(getTripTypeFlight);
  const lastDepartureDate = useSelector(getLastDepartureDateFlight);
  const pointOfSale = usePointOfSale();
  const contextName = useContextName();
  const esimCheckFeature = !!features["FLIGHTS_ESIM_ELIGIBILTY_CHECK_WEB"];

  return useCallback(() => {
    const shouldPost = !!userEmail && esimCheckFeature && tripType;
    if (!shouldPost) return;

    dispatch(esimActions.fetch());
    return clientFetch("/api/esimEligibility/check", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Flights-Context-Name": contextName
      },
      body: JSON.stringify({
        pointOfSale,
        geniusLevel,
        lastDepartureDate,
        arrivalCountry,
        tripType,
        arrivalPlaceType: arrivalPlace?.type,
        arrivalPlaceName: arrivalPlace?.code
      })
    })
      .then((data: UIEsim) => dispatch(esimActions.fetchSuccess(data)))
      .catch((error) => dispatch(esimActions.fetchError(error)));
  }, [
    userEmail,
    lastDepartureDate,
    arrivalPlace,
    tripType,
    arrivalCountry,
    contextName,
    dispatch,
    geniusLevel,
    pointOfSale,
    esimCheckFeature
  ]);
};

export const useEsimOrder = (source?: "EMAIL" | "WEB") => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const geniusLevel = useSelector(getGeniusLevel)?.toString();
  const pointOfSale = usePointOfSale();
  const orderId = useSelector(getOrderId);
  const arrivalPlace = useSelector(getArrivalPlaceOrder);
  const tripType = useSelector(getTripTypeOrder);
  const lastDepartureDate = useSelector(getLastDepartureDateOrder);
  const orderStatus = useSelector(getOrderStatus);
  const contextName = useContextName();
  const arrivalCountry = useSelector(getArrivalCountryCodeOrder);
  const bookedAt = useSelector(getOrderBookedAt);

  return useCallback(() => {
    const isPastBooking = bookedAt ? differenceInCalendarDays(new Date(bookedAt), new Date(2024, 11, 1)) <= 0 : true;
    const shouldPost =
      !!userEmail && !isPastBooking && (source === "EMAIL" || !!flights_ace_web_esim_banner.trackWithDefaultStage());
    if (!shouldPost) return;

    dispatch(esimActions.fetch());
    return clientFetch("/api/esimEligibility/generateLink", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Flights-Context-Name": contextName
      },
      body: JSON.stringify({
        orderId,
        pointOfSale,
        geniusLevel,
        lastDepartureDate,
        arrivalCountry,
        tripType,
        arrivalPlaceType: arrivalPlace?.type,
        arrivalPlaceName: arrivalPlace?.code,
        orderStatus,
        bookedAt
      })
    })
      .then((data: UIEsim) => dispatch(esimActions.fetchSuccess(data)))
      .catch((error) => dispatch(esimActions.fetchError(error)));
  }, [
    contextName,
    dispatch,
    geniusLevel,
    orderId,
    orderStatus,
    pointOfSale,
    lastDepartureDate,
    arrivalPlace,
    tripType,
    arrivalCountry,
    bookedAt,
    source,
    userEmail
  ]);
};

export const useEsimIndex = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);
  const geniusLevel = useSelector(getGeniusLevel)?.toString();
  const contextName = useContextName();

  return useCallback(() => {
    const shouldPost = !!userEmail;
    if (!shouldPost) return;

    dispatch(esimActions.fetch());
    return clientFetch("/api/esimEligibility/getLink", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Flights-Context-Name": contextName
      },
      body: JSON.stringify({
        geniusLevel
      })
    })
      .then((data: UIEsim) => dispatch(esimActions.fetchSuccess(data)))
      .catch((error) => dispatch(esimActions.fetchError(error)));
  }, [contextName, dispatch, geniusLevel, userEmail]);
};
