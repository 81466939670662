import React, { FC, useState, useCallback, MouseEvent, useMemo } from "react";
import { Text, Button, useTheme, Card, Divider, Stack } from "@bookingcom/bui-react";
import BreakdownList from "./components/BreakdownList";
import DetailedBreakdownList from "./components/DetailedBreakdownList";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";

import {
  UIOrderExtras,
  UIPriceBreakdown,
  UIPassenger,
  UITravellerPrice,
  UITraveller,
  UISubsidizedFareType,
  UIFlightData,
  UIAirOrder,
  UIAirOrderAtolProtection
} from "@flights/types";
import useEventTracking from "../../../hooks/useEventTracking";
import useUserAgent from "../../../hooks/useUserAgent";

import useGlobalContext from "../../../hooks/useGlobalContext";
import { ATOLPriceBreakdownFooter } from "../ATOL";
import useModalDialog from "hooks/useModalDialog";
import InstalmentsBanner from "./components/InstalmentsBanner";
import isHungaryDepartingRyanAirFlight from "utils/hungaryTaxes";
import PriceTransparencyText from "./components/PriceTransparencyText";
import useRouteName from "hooks/useRouteName";
import { MpProduct } from "@bookingcom/mp-flights";
import styles from "./PriceBreakdown.module.css";
import FlightAvailableSeats from "../FlightCard/components/FlightAvailableSeats";
import Frame from "../Frame";
import { trackOpenDetailedBreakdownCheckout } from "utils/experiments/funnel/flights_web_price_breakdown_travellers_split";

/** ! The current version of BUI is keeping the whole body un-scrollable after we close a BottomSheet. This "fixes" it;
 */
const resetBodyOverflow = () => {
  document.body.style.overflow = "auto";
};

type Props = {
  totalPrice: UIPriceBreakdown;
  travellers?: UIPassenger[] | UITraveller[];
  travellerPrices?: UITravellerPrice[];
  ancillaries?: UIOrderExtras;
  showTitle?: boolean;
  fullBreakdownAvailable?: boolean;
  showFlightDetails?: boolean;
  showAncillariesDetails?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  appliedSubsidizedFares?: UISubsidizedFareType[];
  priceTaxBreakdown?: any;
  atolProtection?: UIAirOrderAtolProtection; // passed from post booking
  flightData?: UIFlightData;
  totalTagName?: string;
  orderData?: UIAirOrder;
  multiProducts?: MpProduct[];
  showAvailableSeats?: boolean;
  paymentCollectionToPay?: string; // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  paymentCollectionPaid?: string; // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
  isBrandedFare?: boolean;
  isPC?: boolean;
  showPricePerTraveller?: boolean;
  isPostbook?: boolean;
};

const PriceBreakdown: FC<Props> = (props) => {
  const {
    totalPrice,
    travellers,
    travellerPrices,
    ancillaries,
    showTitle = true,
    fullBreakdownAvailable = true,
    showFlightDetails = false,
    showAncillariesDetails = false,
    onClose,
    onOpen,
    appliedSubsidizedFares,
    atolProtection,
    priceTaxBreakdown,
    flightData,
    totalTagName,
    orderData,
    multiProducts = [],
    paymentCollectionToPay, // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
    paymentCollectionPaid, // FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE
    showAvailableSeats = false,
    isBrandedFare = false,
    showPricePerTraveller = false,
    isPostbook
  } = props;
  const theme = useTheme();
  const i18n = useI18n() as I18nChildContext;
  const { isMobile } = useUserAgent();
  const Modal = useModalDialog();
  const [showFullBreakdown, setShowFullBreakdown] = useState<boolean>(false);
  const currentRoute = useRouteName();

  const segments = flightData?.segments || orderData?.flightSegments;

  const onModalClose = () => {
    setShowFullBreakdown(false);
    resetBodyOverflow();
    onClose && onClose();
  };

  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("price_breakdown", requestId);

  const handlePriceBreakdownClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      setShowFullBreakdown(true);
      trackV2("click_view_price_breakdown");
      trackV2("click_price_breakdown");
      onOpen && onOpen();
      trackOpenDetailedBreakdownCheckout({
        childrenPriceBreakDown: travellerPrices
          ?.filter((t) => t.travellerType === "KID")
          .map((t) => t.travellerPriceBreakdown)
      });
    },
    [trackV2, onOpen, travellerPrices]
  );

  const isPriceTransparencyCopyApplicable = useMemo(() => {
    //https://et.booking.com/experiment/1661770.html
    const priceTransparencyTextApplicableRoutes = [
      "checkout3",
      "checkout-ticket-type",
      "checkout-seat-selection",
      "checkout2", //ancillaries
      "checkout" //pax
    ];
    return priceTransparencyTextApplicableRoutes.includes(currentRoute);
  }, [currentRoute]);

  let showMissingPaymentMade = true;

  // We've discovered cases where the BE can a remaining amount greater than the total amount to be paid
  // This is to ensure that we are not displaying negative values or 0 values in the payment breakdown
  const paymentCollectionPaidAmount = parseFloat((paymentCollectionPaid || "").replace(/[^0-9.-]/g, ""));
  if (paymentCollectionPaidAmount <= 0) {
    showMissingPaymentMade = false;
  }

  return (
    <div data-testid="price_breakdown_popover">
      <BreakdownList
        showFlightDetails={showFlightDetails}
        showAncillariesDetails={showAncillariesDetails}
        showTitle={showTitle}
        totalPrice={totalPrice}
        travellers={travellers}
        travellerPrices={travellerPrices}
        ancillaries={ancillaries}
        appliedSubsidizedFares={appliedSubsidizedFares}
        priceTaxBreakdown={priceTaxBreakdown}
        flightData={flightData}
        totalTagName={totalTagName}
        multiProducts={multiProducts}
        isRynaAirHungaryFlight={isHungaryDepartingRyanAirFlight(segments)}
        isBrandedFare={isBrandedFare}
        isOrder={!!orderData}
        showPricePerTraveller={showPricePerTraveller}
        isPostbook={isPostbook}
      />

      {isMobile ? <InstalmentsBanner /> : undefined}

      {isPriceTransparencyCopyApplicable ? <PriceTransparencyText /> : null}

      <ATOLPriceBreakdownFooter atolProtection={atolProtection} />

      {showAvailableSeats && flightData && (
        <Frame pb={4}>
          <FlightAvailableSeats flight={flightData} />
        </Frame>
      )}

      {/* Ryan Air price breakdown disclaimer for Hungary */}
      {isHungaryDepartingRyanAirFlight(segments) && (
        <Card>
          <Text variant="small_2">{i18n.trans(t("flights_ryanair_hungary_price_breakdown_bottom_hed"))}</Text>
          <Text variant="small_2">{i18n.trans(t("flights_ryanair_hungary_price_breakdown_bottom"))}</Text>
        </Card>
      )}

      {/* FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE */}
      {/* ================================================================== */}
      {paymentCollectionToPay || paymentCollectionPaid ? (
        <Stack gap={3}>
          <Stack.Item>
            <Divider />
          </Stack.Item>
          {paymentCollectionPaid && showMissingPaymentMade && (
            <Stack direction="row">
              <Stack.Item grow>
                <Text variant="strong_2">{i18n.trans(t("flights_price_breakdown_missing_payment_paid"))}</Text>
              </Stack.Item>
              <Stack.Item>
                <Text variant="strong_2">{paymentCollectionPaid}</Text>
              </Stack.Item>
            </Stack>
          )}
          {paymentCollectionToPay && (
            <Stack direction="row">
              <Stack.Item grow>
                <Text variant="strong_2">{i18n.trans(t("flights_price_breakdown_missing_payment_to_pay"))}</Text>
              </Stack.Item>
              <Stack.Item>
                <Text variant="strong_2">{paymentCollectionToPay}</Text>
              </Stack.Item>
            </Stack>
          )}
        </Stack>
      ) : undefined}
      {/* ================================================================== */}
      {/* FLIGHTS_WEB_PAYMENT_COLLECTION_NEW_PAGE */}

      {fullBreakdownAvailable ? (
        <div style={{ paddingTop: theme.units.spacing_2x }}>
          <a
            className={styles.viewBreakdownLink}
            href="#"
            onClick={(e) => {
              handlePriceBreakdownClick(e);
            }}
          >
            <Text
              variant="body_2"
              attributes={{
                "data-testid": "price_breakdown_view_price_breakdown",
                "aria-label": i18n.trans(t("flights_price_breakdown_view"))
              }}
            >
              {i18n.trans(t("flights_price_breakdown_view"))}
            </Text>
          </a>
        </div>
      ) : null}

      {!isMobile ? <InstalmentsBanner /> : null}

      <Modal
        title={i18n.trans(t("flights_price_breakdown"))}
        closeAriaLabel={i18n.trans(t("a11y_flights_close_price_details"))}
        onCloseTrigger={onModalClose}
        active={showFullBreakdown}
      >
        <DetailedBreakdownList
          showTitle={false}
          showFlightDetails
          showAncillariesDetails
          totalPrice={totalPrice}
          travellers={travellers}
          travellerPrices={travellerPrices}
          ancillaries={ancillaries}
          appliedSubsidizedFares={appliedSubsidizedFares}
          flightData={flightData}
          instalmentsFees={totalPrice.instalmentsFees}
          orderData={orderData}
          multiProducts={multiProducts}
          isRynaAirHungaryFlight={isHungaryDepartingRyanAirFlight(segments)}
          isBrandedFare={isBrandedFare}
        />
        <ATOLPriceBreakdownFooter atolProtection={atolProtection} />
        <Button
          onClick={onModalClose}
          text={i18n.trans(t("close"))}
          attributes={{ "data-testid": "price_breakdown_close" }}
          wide={isMobile}
        />
      </Modal>
    </div>
  );
};

export default PriceBreakdown;
