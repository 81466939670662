import memoize from "lodash/memoize";
import { getCurrencySymbol, localizer } from "./currency-localizer/localizer.js";
import { abbreviateNumber } from "./number.js";
export const priceIsZero = (price) => price.units === 0 && price.nanos === 0;
// NOTE: Only memoizes on locale, currency and maximumFractionDigits for performance reasons, extend the key when needed.
const NumberFormatMemoized = memoize(Intl.NumberFormat, 
// This function creates the key to use for memoization.
(locale, options) => String(locale) + String(options?.currency) + String(options?.maximumFractionDigits));
export const priceToValue = ({ nanos, units }) => {
    const sign = Math.sign(units !== 0 ? units : nanos) || 1;
    const normalizedNanos = sign * Math.abs(nanos / 1e9);
    return units + normalizedNanos;
};
export const formatPrice = (price, locale, formatOptions) => {
    let value = priceToValue(price);
    if (formatOptions?.isDiscount) {
        value = 0 - value;
    }
    if (formatOptions?.useNativeFormatting) {
        return NumberFormatMemoized(locale, {
            style: "currency",
            currency: price.currencyCode,
            maximumFractionDigits: formatOptions?.decimalPlaces
        }).format(value);
    }
    return localizer(value, {
        currency: price.currencyCode,
        /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
        //fixme: locale is not valid, does not have country, thus use point of sale for now
        locale,
        decimalPlaces: formatOptions?.decimalPlaces
    });
};
export const formatAbbreviatedPrice = (price, preferredMaxLength = 5) => {
    const value = priceToValue(price);
    const currencySymbol = getCurrencySymbol(price.currencyCode);
    let digits = 2;
    let abbreviatedValue = abbreviateNumber(value, digits);
    while (abbreviatedValue.length > preferredMaxLength && digits > 0) {
        digits -= 1;
        abbreviatedValue = abbreviateNumber(value, digits);
    }
    return `${currencySymbol} ${abbreviatedValue.toUpperCase()}`;
};
