/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { Text } from "@bookingcom/bui-react";
import { useI18n, I18nChildContext } from "@bookingcom/lingojs-react";
import { UIAirOrderAtolProtection } from "@flights/types";
import { t } from "@bookingcom/lingojs-core";
import useATOL from "hooks/useATOL";
import Frame from "../Frame";

interface ATOLPriceBreakdownFooterProps {
  atolProtection?: UIAirOrderAtolProtection;
}

const ATOLPriceBreakdownFooterProtected: React.FC = () => {
  const i18n = useI18n() as I18nChildContext;

  return (
    <Frame mt={4} mb={4}>
      <Text tagName="span" variant="small_1" attributes={{ "data-testid": "ATOL_price_breakdown_footer_text" }}>
        {i18n.trans(t("flights_atol_price_breakdown"))}
      </Text>
    </Frame>
  );
};

const ATOLPriceBreakdownPBFooterProtected: React.FC = () => {
  const i18n = useI18n() as I18nChildContext;

  return (
    <Frame mt={4} mb={4}>
      <Text tagName="span" variant="small_1" attributes={{ "data-testid": "ATOL_price_breakdown_footer_text" }}>
        {i18n.trans(t("flights_pb_price_breakdown_atol_protected"))}
      </Text>
    </Frame>
  );
};

const ATOLPriceBreakdownPBFooterNonProtected: React.FC = () => {
  const i18n = useI18n() as I18nChildContext;

  return (
    <Frame mt={4} mb={4}>
      <Text tagName="span" variant="small_1" attributes={{ "data-testid": "ATOL_price_breakdown_footer_text" }}>
        {i18n.trans(t("flights_pb_price_breakdown_atol_not_protected"))}
      </Text>
    </Frame>
  );
};

const ATOLPriceBreakdownFooter: React.FC<ATOLPriceBreakdownFooterProps> = ({ atolProtection }) => {
  const { isATolProtectedCheckOut } = useATOL();

  // Check if atolProtection has been passed from post book pages,
  // if not fall back to isATolProtectedCheckOut() to render atol information on check out pages
  if (atolProtection === "UNKNOWN") return null;

  if (atolProtection === "PROTECTED" || atolProtection === "PROTECTED_BEFORE_EXEMPTION") {
    return <ATOLPriceBreakdownPBFooterProtected />;
  }

  if (atolProtection === "NON_PROTECTED") {
    return <ATOLPriceBreakdownPBFooterNonProtected />;
  }

  if (isATolProtectedCheckOut()) {
    return <ATOLPriceBreakdownFooterProtected />;
  }

  return null;
};

export default ATOLPriceBreakdownFooter;
