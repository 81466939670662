import {
  UIOrderExtras,
  UIOrderTravellerBaggage,
  UIProductCheckedInBaggageSelected,
  UITravellerPrice,
  UIProductFlexibleTicket,
  UIProductFlexibleTicketSelected,
  UIOrderMealPreference,
  UIProductMealPreferenceSelected,
  UIProductTravelInsuranceSelected,
  UIOrderSeatingOption,
  UIProductSeatingPreferenceSelected,
  UIProductSeatMapSelected,
  UIProductSelection,
  UIAvailableExtraProducts,
  UIFlightData,
  UIPreOptInSelected,
  UILuggageCheckedAllowance,
  UIProductCabinBaggagePerTravellerSelected
} from "@flights/types";

import { pricingUtils } from "@flights/web-api-utils-universal";

export const selectedAncillariesToAddedAncillaries = (
  selected: UIProductSelection,
  ancillaries: UIAvailableExtraProducts
): UIOrderExtras | undefined => {
  const output: UIOrderExtras = {};
  if (selected && Object.keys(selected).length > 0 && ancillaries) {
    Object.keys(selected).forEach((productType) => {
      // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
      const items = selected[productType];
      switch (productType) {
        case "cabinBaggage": {
          const product = ancillaries.cabinBaggage;
          if (items.length && product) {
            output["cabinBaggage"] = {
              reference: "n/a",
              price: product.priceBreakdown
            };
          }
          break;
        }
        case "cabinBaggagePerTraveller": {
          const ancillaryProduct = ancillaries.cabinBaggagePerTraveller;

          if (items.length && ancillaryProduct) {
            const travelerBaggages: UIOrderTravellerBaggage[] = items.map(
              (selectedItem: UIProductCabinBaggagePerTravellerSelected) => {
                const { luggageAllowance, priceBreakdown } = ancillaryProduct;
                const { travellerReference } = selectedItem;

                return {
                  price: priceBreakdown,
                  luggageAllowance,
                  travellerReference
                };
              }
            );

            output["cabinBaggagePerTraveller"] = {
              reference: "n/a",
              price: pricingUtils.sumPriceBreakdown(travelerBaggages.map((item) => item.price)),
              travelerBaggages
            };
          }
          break;
        }
        case "checkedInBaggage": {
          const product = ancillaries.checkedInBaggage;
          if (items.length && product) {
            const travelerBaggages: UIOrderTravellerBaggage[] = items.map((item: UIProductCheckedInBaggageSelected) => {
              const selectedProduct = product.options[item.selectedProductIndex];
              return {
                price: selectedProduct.priceBreakdown,
                luggageAllowance: selectedProduct.luggageAllowance,
                travellerReference: item.travellerReference
              };
            });
            output["checkedInBaggage"] = {
              reference: "n/a",
              price: pricingUtils.sumPriceBreakdown(travelerBaggages.map((item) => item.price)),
              travelerBaggages
            };
          }
          break;
        }
        case "flexibleTicket": {
          const product = ancillaries.flexibleTicket;
          if (items.length && product) {
            const travelerPrices: UITravellerPrice[] = items.map((item: UIProductFlexibleTicketSelected) => {
              return {
                travellerPriceBreakdown: product.priceBreakdown,
                travellerReference: item.travellerReference
              };
            });
            output["flexibleTicket"] = {
              reference: "n/a",
              price: pricingUtils.sumPriceBreakdown(travelerPrices.map((item) => item.travellerPriceBreakdown)),
              travelerPrices,
              supplierInfo: product.supplierInfo
            };
          }
          break;
        }
        case "mealPreference": {
          const product = ancillaries.mealPreference;
          if (items.length && product) {
            const mealPreferences: UIOrderMealPreference[] = items.map((item: UIProductMealPreferenceSelected) => {
              const selectedProduct = product.choices[item.selectedProductIndex];
              return {
                price: selectedProduct.priceBreakdown,
                mealType: item.mealType,
                travellerReference: item.travellerReference
              };
            });
            output["mealChoice"] = {
              reference: "n/a",
              price: pricingUtils.sumPriceBreakdown(mealPreferences.map((item) => item.price)),
              mealPreferences
            };
          }
          break;
        }
        case "mobileTravelPlan": {
          const product = ancillaries.mobileTravelPlan;
          if (items.length && product) {
            output["mobileTravelPlan"] = {
              reference: "n/a",
              price: product.priceBreakdown
            };
          }
          break;
        }
        case "seatingBesides": {
          const product = ancillaries.seatingBesides;
          if (items.length && product) {
            output["seatingBesides"] = {
              reference: "n/a",
              price: product.priceBreakdown
            };
          }
          break;
        }
        case "travelInsurance": {
          const travelInsurance = ancillaries.travelInsurance;

          if (travelInsurance) {
            const { config, documents, options: insuranceProduct, isPerTraveller, version } = travelInsurance;
            if (items.length && insuranceProduct) {
              const travelerPrices: UITravellerPrice[] = items.map((item: UIProductTravelInsuranceSelected) => {
                return {
                  travellerPriceBreakdown: insuranceProduct.priceBreakdown,
                  travellerReference: item.travellerReference
                };
              });
              output["travelInsurance"] = {
                reference: "n/a",
                price: isPerTraveller
                  ? pricingUtils.sumPriceBreakdown(travelerPrices.map((item) => item.travellerPriceBreakdown))
                  : insuranceProduct.priceBreakdown,
                travelerPrices,
                config,
                documents,
                isPerTraveller,
                version
              };
            }
          }

          break;
        }
        case "seatingPreference": {
          const product = ancillaries.seatingPreference;
          if (items.length && product) {
            const seatingOptions: UIOrderSeatingOption[] = items.map((item: UIProductSeatingPreferenceSelected) => {
              const selectedProduct = product.offers[item.selectedProductIndex];
              return {
                price: selectedProduct.priceBreakdown,
                seatingType: selectedProduct.seatingType,
                travellerReference: item.travellerReference
              };
            });
            output["seatingPreference"] = {
              reference: "n/a",
              price: pricingUtils.sumPriceBreakdown(seatingOptions.map((item) => item.price)),
              seatingOptions
            };
          }
          break;
        }
        case "seatMapSelection": {
          const seatMapSelection = selected && selected.seatMapSelection;
          if (items.length && seatMapSelection) {
            const travelerPrices: UITravellerPrice[] = items.map((item: UIProductSeatMapSelected) => {
              return {
                travellerPriceBreakdown: item.price,
                travellerReference: item.travellerReference
              };
            });
            output["seatMapSelection"] = {
              reference: "n/a",
              price: pricingUtils.sumPriceBreakdown(travelerPrices.map((item) => item.travellerPriceBreakdown)),
              travelerPrices,
              seats: items
            };
          }
          break;
        }
        case "cancelForAnyReason": {
          const product = ancillaries.cancelForAnyReason;

          if (product && items?.length > 0) {
            output["cancelForAnyReason"] = {
              reference: "n/a",
              price: product.priceBreakdown
            };
          }

          break;
        }
        case "fastTrack": {
          const product = ancillaries.fastTrack;

          if (product && items?.length > 0) {
            output["fastTrack"] = {
              reference: "n/a",
              price: product.priceBreakdown,
              status: product.status,
              supplierInfo: product.supplierInfo
            };
          }

          break;
        }
        default: {
          // do nothing
        }
      }
    });
  }
  return Object.keys(output).length ? output : undefined;
};

export const preOptedToAddedAncillaries = (
  preOptedAncillaries: UIPreOptInSelected,
  flight: UIFlightData
): UIOrderExtras | undefined => {
  const { ancillaries } = flight;
  if (!ancillaries) {
    return undefined;
  }

  const preOptedAsSelectedAncillaries: UIProductSelection = {};

  Object.keys(preOptedAncillaries).forEach((ancillaryType) => {
    // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
    const extraProductData = ancillaries[ancillaryType];

    if (extraProductData) {
      switch (ancillaryType) {
        case "flexibleTicket":
          const { travellers: flexTicketTravellers } = extraProductData as UIProductFlexibleTicket;
          preOptedAsSelectedAncillaries.flexibleTicket = flexTicketTravellers.map((travellerReference) => {
            return {
              travellerReference,
              selectedProductIndex: 0,
              airProductReference: "n/a"
            };
          });

          break;
        case "cancelForAnyReason":
          preOptedAsSelectedAncillaries.cancelForAnyReason = [{ ...extraProductData }];

          break;

        case "checkedInBaggage":
          const checkedInBaggageData = ancillaries[ancillaryType];
          if (!checkedInBaggageData) {
            return;
          }
          const airProductReference: string = checkedInBaggageData.airProductReference || "n/a";
          const checkedInBaggageOption = checkedInBaggageData?.options;

          checkedInBaggageOption.map((eachOption) => {
            const allowance = eachOption.luggageAllowance as UILuggageCheckedAllowance;

            preOptedAsSelectedAncillaries.checkedInBaggage = eachOption.travellers?.map((traveller) => {
              return {
                pieces: allowance.maxPiece,
                weight: allowance.maxWeightPerPiece,
                travellerReference: traveller,
                airProductReference,
                selectedProductIndex: 0
              };
            });
          });

          break;

        case "mobileTravelPlan":
          preOptedAsSelectedAncillaries.mobileTravelPlan = [{ airProductReference: "n/a" }];
          break;
      }
    }
  });

  return selectedAncillariesToAddedAncillaries(preOptedAsSelectedAncillaries, ancillaries);
};

export const getAddedAncillariesExtraProduct = (
  extraProducts: UIAvailableExtraProducts | undefined,
  selectedExtraProducts: UIProductSelection | undefined
): UIOrderExtras | undefined => {
  const ancillaries = extraProducts || {};
  return selectedExtraProducts && selectedAncillariesToAddedAncillaries(selectedExtraProducts, ancillaries);
};
