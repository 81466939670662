export enum RatingTypes {
  DOTS = "DOTS",
  STARS = "STARS",
  TILES = "TILES",
  NONE = "NONE"
}

export enum PaxTypes {
  ADULT = "ADULT",
  KID = "KID"
}

export const NUMBER_OF_RESULTS = 8;
export const MDOT_NUMBER_OF_RESULTS = 3;

export const ACCOMMODATION_FIELDS_TO_CHECK = [
  "acceptsWalletCredits",
  "formattedDisplayPrice",
  "geniusProperty",
  "id",
  "name",
  "photo",
  "propertyTarget",
  "reqResultsId"
];

export const C360_ACTION_VERSION = "1.1.0";

export const PLATFORM_TYPE = {
  ANDROID: "Android",
  WWW: "Desktop",
  IOS: "iOS",
  MDOT: "Mobile",
  TDOT: "Tablet"
};

export const MY_TRIPS = "mytrips";
export const SR_LINK = "SRLink";
export const EMAIL = "email";

export const MERCH = "abu_index";
export const CAROUSEL = "carousel";
export const ACCOMMODATION = "accommodation";

export const CONNECTOR_FEEDBACK_RESPONSES = {
  I_DONT_NEED_TO_BOOK_A_STAY: 1,
  I_ALREADY_HAVE_BOOKED_A_STAY: 2,
  I_AM_NOT_READY_TO_BOOK_A_STAY: 3,
  OTHER: 4
};
