/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Actionable, Button, Card, Divider, Icon, Stack } from "@bookingcom/bui-react";

import { trackCustomGoal, trackExperiment, trackExperimentStage } from "utils/et";
import BaseFareAttributes from "./BaseFareAttributes";
import BaseFareName from "./BaseFareName";
import FlightCardCarriers from "./FlightCardCarriers";
import FlightCardLuggage from "./FlightCardLuggage";
import FlightCardPrice from "./FlightCardPrice";
import FlightCardSegmentMobile from "./FlightCardSegment.mobile";
import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useStore } from "../../../../store";
import { FlightCardBoundProps } from "./FlightCardBound.desktop";
import { ArrowNavDownIcon, ArrowNavUpIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { AnimatePresence, motion } from "framer-motion";
import useIsInViewport from "../../../../hooks/useIsInViewport";
import FareSelector from "components/elements/FareSelector/components/FareSelector";
import useFetchBrandedFares from "components/elements/FareSelector/hooks/useFetchBrandedFares";
import FlightCardFullLuggageData from "./FlightCardFullLuggageData";
import { isFeatureRunningClientSide } from "utils/features";
import { FlightCardHighlight, FlightCardHighlightStyle } from "./FlightCardHighlight";
import { FlightCardBadges } from "./FlightCardBadges";
import { FlightCardBadge } from "./FlightCardBadge";
import Frame from "components/elements/Frame";
import styles from "./FlightCardBound.module.css";
import { getCampaignBadgesConfig, getCampaignBestOfferHighlightConfig } from "utils/flightCampaignConfig";
import { RebookCostBreakdown } from "components/elements/RebookFlow/RebookCostBreakdown/RebookCostBreakdown";
import { FlightBadgeVariant } from "@flights/types";
import { mcn } from "../../../../utils/mergeClassnames";
import flights_apex_web_ancillaries_micro_conversion_aa from "utils/experiments/apex/flights_apex_web_ancillaries_micro_conversion_aa";
import { isOfMetaOrigin } from "utils/marketing-url-params";
import useProfiler from "hooks/useProfiler";
import flights_apex_web_q3_2024_blockout from "utils/experiments/apex/flights_apex_web_q3_2024_blockout";
import { carrierUtils } from "@flights/web-api-utils-universal";
import useGlobalContext from "hooks/useGlobalContext";

type Props = FlightCardBoundProps;

const FlightCardBound: FC<Props> = (props) => {
  const {
    flight,
    onClick,
    pricePerPerson,
    rebookProps,
    /* start - flights_web_inbound_outbound_sr */
    open,
    inboundFlight,
    inboundFlightsContent,
    inboundFlightsCount,
    forceButtonFocusOnMount = false,
    /* end - flights_web_inbound_outbound_sr */
    /* start - flights_alternative_fares_for_sold_out_errors_web */
    forceHideLastAvailableSeats = false,
    isAlternativeFareCard = false,
    /* end - flights_alternative_fares_for_sold_out_errors_web */
    brandedFaresShownByDefault
  } = props;
  const [breakdownOpen, setBreakdownOpen] = useState(false);
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const { searchCriteria, brandedFares } = useStore();
  const { adults, children } = searchCriteria;
  const i18n = useI18n() as I18nChildContext;
  const { isInternal } = useGlobalContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const hasPrefetchedBrandedFareOffers =
    !flight.requestableBrandedFares && !!flight.brandedFareOffers && flight.brandedFareOffers?.length > 0;
  const showFareSelectorDefault =
    (flight.requestableBrandedFares || (flight.brandedFareOffers && flight.brandedFareOffers?.length > 0)) &&
    !!brandedFaresShownByDefault;
  const [showFareSelector, toggleShowFareSelector] = useState(showFareSelectorDefault);
  const flightCardBoundRef = useRef<HTMLButtonElement>(null);
  const isInViewport = useIsInViewport(flightCardBoundRef.current);

  const shouldPinOfferOnSr =
    flight.highlightInfo?.highlight &&
    ((Boolean(trackExperiment("flights_web_cat_pin_flight_mdot")) && isOfMetaOrigin()) ||
      isFeatureRunningClientSide("FLIGHTS_WEB_PIN_OFFER_ON_SR_FROM_REDIRECT") ||
      flight.highlightInfo.reason === "CROSS_SELL_OFFER");

  const { bestOfferHighlightStyle, bestOfferHighlightText } = getCampaignBestOfferHighlightConfig(
    flight.campaignDisplay?.bestOfferHighlight
  );
  const shouldShowBestOfferHightlight = !!(bestOfferHighlightStyle && bestOfferHighlightText);

  const campaignBadges = getCampaignBadgesConfig(flight.campaignDisplay?.badges);

  const carriers = carrierUtils.getMarketingCarriers(flight.segments);

  const brandedFaresAvailable = (brandedFares.fareOffersWithDetails[flight.token]?.brandedFareOffers?.length || 0) > 0;
  const hideBrandedFaresBeforeAvailable = isFeatureRunningClientSide("FLIGHTS_ONLY_SHOW_BF_ON_SR_CTA_WHEN_AVAILABLE");

  useFetchBrandedFares(
    flight.token,
    isInViewport,
    flight.requestableBrandedFares ?? false,
    true,
    !!flight.brandedFareInfo?.fareName,
    carriers
  );

  useEffect(() => {
    if (forceButtonFocusOnMount && buttonRef.current) {
      buttonRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const travellersCount = adults + children.length;
  const isOutboundFlightCard = typeof open !== "undefined";
  const hasMoreThanOneInboundFlight = (flight.outboundFlightInfo?.inboundFlightsCount || 0) > 1;

  const toggleButtonLabel = useMemo(() => {
    if (open) {
      return inboundFlightsCount === 1
        ? t("flights_sr_rt_return_button_hide_single")
        : t("flights_sr_rt_return_button_hide");
    }

    return inboundFlightsCount === 1
      ? t("flights_sr_rt_return_button_view_single")
      : t("flights_sr_rt_return_button_view");
  }, [inboundFlightsCount, open]);

  const handleOnClick = useCallback(() => {
    !breakdownOpen && onClick(flight);
  }, [breakdownOpen, flight, onClick]);

  const hasFlexibleTicketBadge =
    flight.ancillaries?.flexibleTicket &&
    !flight.requestableBrandedFares &&
    !flights_apex_web_q3_2024_blockout.trackWithDefaultStage();
  const showFlightCardBadges =
    flight.badges?.length || campaignBadges.length > 0 || hasFlexibleTicketBadge || flight.hasAppleCardBenefits;

  // flights_web_mdot_sr_card_single_cta -- start
  const profiler = useProfiler();
  const onClickExpand = useCallback(() => {
    trackCustomGoal("flights_web_mdot_sr_card_single_cta", 1);
  }, []);

  useEffect(() => {
    trackExperimentStage("flights_web_mdot_sr_card_single_cta", 1);
    if (profiler.isDirect()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 2);
    if (profiler.isMeta()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 3);
    if (profiler.isSolo()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 4);
    if (!profiler.isSolo()) trackExperimentStage("flights_web_mdot_sr_card_single_cta", 5);
  }, [flight.requestableBrandedFares, profiler]);
  // flights_web_mdot_sr_card_single_cta -- end

  const highlightFireflySR =
    isInternal &&
    flight.token.startsWith("c8e49_") &&
    new URLSearchParams(window.location.search).get("highlight_firefly") === "1";

  return (
    <Card
      fill
      className={mcn(styles.card, open && styles.cardShadow, highlightFireflySR && styles.highlightFirefly)}
      attributes={{ ref: flightCardBoundRef }}
      variant={shouldPinOfferOnSr ? "elevated" : "neutral"}
    >
      <Actionable ref={buttonRef} onClick={handleOnClick} className={mcn(styles.button, styles.outboundButtonCard)}>
        {shouldPinOfferOnSr ? (
          <FlightCardHighlight
            style={FlightCardHighlightStyle.PRIMARY}
            text={flight.highlightInfo?.message || i18n.trans(t("flights_meta_sr_pinned_flight_label"))}
          />
        ) : shouldShowBestOfferHightlight ? (
          <FlightCardHighlight style={bestOfferHighlightStyle} text={bestOfferHighlightText} textEmphasized={true} />
        ) : null}

        <Frame pt={4} pr={4} pb={2} pl={4} direction="column" justifyContent="space-between">
          {showFlightCardBadges && (
            <FlightCardBadges className={styles.badges}>
              {campaignBadges.map((badge, idx) => (
                <FlightCardBadge key={`keyCampaignBadge-${idx}`} alternative={false} isInteractive={false} {...badge} />
              ))}

              {flight?.badges?.map(({ text, variant, type }, idx) => (
                <FlightCardBadge key={text + idx} text={text} style={variant} alternative type={type} />
              ))}

              {hasFlexibleTicketBadge && (
                <FlightCardBadge
                  style={FlightBadgeVariant.CONSTRUCTIVE}
                  alternative={true}
                  text={i18n.trans(t("flights_apex_sr_flex_badge"))}
                />
              )}

              {flight.hasAppleCardBenefits && (
                <FlightCardBadge
                  style={FlightBadgeVariant.CALLOUT}
                  alternative={true}
                  text={i18n.trans(t("apple_rewards_flights_sr_badge_title"))}
                />
              )}
            </FlightCardBadges>
          )}

          <BaseFareAttributes flight={flight} />
          <Frame
            attributes={{
              style: {
                width: "100%"
              }
            }}
            justifyContent="space-around"
          >
            {flight.segments.map((segment, segmentIndex) => {
              return (
                <Frame key={segmentIndex} className={styles.segment}>
                  <FlightCardSegmentMobile segment={segment} idx={segmentIndex} />

                  <Frame>
                    <FlightCardCarriers
                      segment={segment}
                      idx={segmentIndex}
                      isAlternativeFareCard={isAlternativeFareCard}
                    />
                  </Frame>
                </Frame>
              );
            })}
          </Frame>
          {!trackExperiment("flights_web_sr_full_luggage_data") && (isOutboundFlightCard || inboundFlight) && (
            <Frame>
              <FlightCardLuggage
                segments={flight.segments}
                horizontal={true}
                brandedFareInfo={flight.brandedFareInfo}
                isAlternativeFareCard={isAlternativeFareCard}
              />
            </Frame>
          )}
          <Divider className={styles.divider} />
          {!!trackExperiment("flights_web_sr_full_luggage_data") && (
            <>
              <FlightCardFullLuggageData
                includedLuggagePerSegment={flight.includedProducts?.segments}
                segments={flight.segments}
                ancillaries={flight.ancillaries}
                brandedFareInfo={flight.brandedFareInfo}
              />

              <Divider className={styles.divider} />
            </>
          )}
          <Frame direction="row" justifyContent="space-between" alignItems="center" className={styles.priceBlock}>
            {rebookProps ? (
              <Frame direction="row" alignItems="center" justifyContent="flex-start" className={styles.luggage}>
                <FlightCardLuggage
                  segments={rebookProps.tripRebookOption.segments}
                  isAlternativeFareCard={isAlternativeFareCard}
                />
              </Frame>
            ) : !trackExperiment("flights_web_sr_full_luggage_data") && !isOutboundFlightCard && !inboundFlight ? (
              <Frame direction="row" alignItems="center" justifyContent="flex-start" className={styles.luggage}>
                <FlightCardLuggage
                  segments={flight.segments}
                  brandedFareInfo={flight.brandedFareInfo}
                  isAlternativeFareCard={isAlternativeFareCard}
                />
              </Frame>
            ) : null}

            <Frame className={styles.price}>
              <BaseFareName flight={flight} />
              {rebookProps ? (
                <RebookCostBreakdown
                  {...rebookProps}
                  breakdownOpen={breakdownOpen}
                  setBreakdownOpen={setBreakdownOpen}
                />
              ) : (
                <FlightCardPrice
                  flight={flight}
                  breakdownOpen={breakdownOpen}
                  setBreakdownOpen={setBreakdownOpen}
                  showPricePerPerson={pricePerPerson && adults + children.length > 1}
                  showAvailableSeats={!forceHideLastAvailableSeats}
                  totalPriceLabel={
                    isOutboundFlightCard
                      ? i18n.trans(
                          travellersCount > 1 ? t("flights_sr_rt_price_per") : t("flights_sr_price_round_trip")
                        )
                      : undefined
                  }
                  priceFrom={isOutboundFlightCard && hasMoreThanOneInboundFlight}
                  hidePriceBreakDown={isOutboundFlightCard}
                  popoverCloseAriaLabel={i18n.trans(t("flights_a11y_price_breakdown_close_sr"))}
                />
              )}

              {isOutboundFlightCard && (
                <Button
                  text={i18n.trans(toggleButtonLabel)}
                  variant="secondary"
                  iconPosition="end"
                  icon={
                    <Icon
                      svg={ArrowNavDownIcon}
                      className={open ? styles.arrow : undefined}
                      attributes={{ "aria-hidden": true }}
                    />
                  }
                  className={styles.fakeButton}
                  attributes={{ "aria-expanded": open }}
                />
              )}

              {inboundFlight && (
                <Button
                  text={i18n.trans(t("flights_sr_rt_button_details"))}
                  variant="primary"
                  className={styles.fakeButton}
                />
              )}
            </Frame>
          </Frame>
        </Frame>
      </Actionable>

      <Frame className={styles.buttons}>
        <Stack gap={3}>
          {flight.requestableBrandedFares &&
          !!trackExperiment("flights_web_mdot_sr_card_single_cta") &&
          !isAlternativeFareCard ? (
            <Button
              attributes={{ "aria-expanded": showFareSelector, "aria-controls": "flights-fare-selector" }}
              text={i18n.trans(t("flights_sr_bf_cta_ticket_options"))}
              onClick={(e) => {
                e.stopPropagation();
                onClickExpand();
                toggleShowFareSelector(!showFareSelector);
                flights_apex_web_ancillaries_micro_conversion_aa.goals.show_fare_options_sr();
              }}
              variant="secondary"
              iconPosition="end"
              icon={
                <Icon
                  svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon}
                  className={open ? styles.arrow : undefined}
                  attributes={{ "aria-hidden": true }}
                />
              }
              className={`${styles.button} ${
                !brandedFaresAvailable && hideBrandedFaresBeforeAvailable ? styles.hidden : ""
              }`}
            />
          ) : (
            <>
              <Button
                ref={buttonRef}
                attributes={{
                  "aria-label": `${i18n.trans(t("flights_apex_bf_sr_cta1_view_details"))} ${
                    hasFlexibleTicketBadge ? i18n.trans(t("flights_apex_sr_flex_badge")) : ""
                  }`
                }}
                text={i18n.trans(t("flights_apex_bf_sr_cta1_view_details"))}
                onClick={() => {
                  onClick(flight);
                }}
                variant="secondary"
                className={styles.button}
              />
              {(flight.requestableBrandedFares && !isAlternativeFareCard) ||
              (!flight.requestableBrandedFares && hasPrefetchedBrandedFareOffers) ? (
                <Button
                  attributes={{ "aria-expanded": showFareSelector, "aria-controls": "flights-fare-selector" }}
                  text={
                    showFareSelector
                      ? i18n.trans(t("flights_apex_sr_flex_cta_hide"))
                      : i18n.trans(t("flights_apex_sr_flex_cta_explore"))
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickExpand();
                    toggleShowFareSelector(!showFareSelector);
                    flights_apex_web_ancillaries_micro_conversion_aa.goals.show_fare_options_sr();
                  }}
                  variant="tertiary"
                  iconPosition="end"
                  icon={
                    <Icon
                      svg={showFareSelector ? ArrowNavUpIcon : ArrowNavDownIcon}
                      className={open ? styles.arrow : undefined}
                      attributes={{ "aria-hidden": true }}
                    />
                  }
                  className={`${styles.button} ${
                    !brandedFaresAvailable && hideBrandedFaresBeforeAvailable ? styles.hidden : ""
                  }`}
                />
              ) : null}
            </>
          )}
        </Stack>
      </Frame>

      {showFareSelector && <FareSelector flight={flight} />}

      {isOutboundFlightCard && (
        <AnimatePresence initial={false}>
          {open && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              transition={{ duration: 0.2 }}
            >
              <Frame mb={4} pr={4}>
                {inboundFlightsContent}
              </Frame>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </Card>
  );
};

const FlightCard = React.memo(FlightCardBound);

export default FlightCard;
