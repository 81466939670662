import { BagHoldIcon, BagPersonalItemIcon, CabinTrolleyIcon, CancellationPartiallyRefundableIcon, FastTrackIcon, FoodIcon, HealthInsuranceIcon, PhoneCheckmarkIcon, PlaneTicketReturnIcon, SeatRegularIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
// Should this go into a separate package?
export const includedBaggageIcons = {
    PERSONAL_ITEM: BagPersonalItemIcon,
    HAND: CabinTrolleyIcon,
    CHECKED_IN: BagHoldIcon
};
export const productIcons = {
    checkedInBaggage: BagHoldIcon,
    /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
    // FIXME: missing icon
    specialBag: BagHoldIcon,
    /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
    // FIXME: missing icon
    specialBags: BagHoldIcon,
    cabinBaggage: CabinTrolleyIcon,
    cabinBaggagePerTraveller: CabinTrolleyIcon,
    personalItem: BagPersonalItemIcon,
    flexibleTicket: PlaneTicketReturnIcon,
    mobileTravelPlan: PhoneCheckmarkIcon,
    travelInsurance: HealthInsuranceIcon,
    /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
    // FIXME should be 'mealPreference', this is the exact opposite of add_ancillaries
    mealChoice: FoodIcon,
    mealPreference: FoodIcon,
    seatingBesides: SeatRegularIcon,
    seatingPreference: SeatRegularIcon,
    seatMapSelection: SeatRegularIcon,
    seatMap: SeatRegularIcon,
    cancelForAnyReason: CancellationPartiallyRefundableIcon,
    fastTrack: FastTrackIcon
};
