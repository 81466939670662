import React from "react";

export type TrackerEvent = {
  type: string;
  action?: string;
  args: any[];
  data?: {
    sessionId?: string;
  };
  /*eslint-disable-next-line flights/no-unassigned-todo-comments*/
  internalData?: any; //TODO
};

export type TrackerContextProps = {
  trackExternal: (event: TrackerEvent) => void;
};

export default React.createContext<TrackerContextProps>({
  trackExternal: () => {}
});
