import { useMemo, useCallback } from "react";
import { format, startOfYear, addMonths, startOfWeek, addDays } from "date-fns";
//HH:mm will show 24 hour watch like 18:30
//hh:mm a will show 12 hour watch like 6:30PM
export const FLIGHT_TIME_AND_DATE = "HH:mm - MMM dd";
export const FLIGHT_TIME_AND_DATE_AM_PM = "hh:mm a - MMM dd";
export const FLIGHT_DATE_AND_TIME = "MMM dd, HH:mm";
export const FLIGHT_DATE_AND_TIME_AM_PM = "MMM dd, hh:mm a";
export const FLIGHT_DATE_AND_TIME_WITH_WEEKDAY = "eee, MMM dd · HH:mm";
export const FLIGHT_DATE_AND_TIME_WITH_WEEKDAY_AM_PM = "eee, MMM dd · hh:mm a";
export const FLIGHT_DATE_WITH_WEEKDAY = "eee, MMM dd";
export const FLIGHT_TIME = "HH:mm";
export const FLIGHT_TIME_AM_PM = "hh:mm a";
export const FLIGHT_DATE = "MMM dd";
export const BIRTH_DATE = "MMM dd, yyyy";
export const SYSTEM_DATE = "yyyy-MM-dd";
export const FLIGHT_DATE_SEARCH_SUMMARY = "dd MMM";
const AM_PM_LOCALE = "en-us";
export function parseDateISO8601(s) {
    const [year, month, day, hours, minutes, seconds, tzHoures, tzMinutes] = s.split(/[^0-9]/);
    if (tzHoures || tzMinutes) {
        // eslint-disable-next-line flights/no-unassigned-todo-comments
        // TODO: FIX ME
        // throw new Error("Expected date-time without a timezone offset");
    }
    return new Date(Number(year), Number(month) - 1, Number(day), hours ? Number(hours) : 0, minutes ? Number(minutes) : 0, seconds ? Number(seconds) : 0);
}
// eslint-disable-next-line
function useDateFormat(locale) {
    const dateFormat = useCallback((date, formatStr) => {
        // date-fns appears to adjust DST when it parses strings
        // (eg. 2019-10-26T08:55:00 becomes 2019-10-26 09:55:00).
        // Date.parse() in Safari doesn't support timezoneless date-time:
        // (eg. 2019-10-26T08:55:00 becomes 2019-10-26 10:55:00 CEST).
        // Flights times are always in local time, thus we don't need to do such adjustments,
        // and this is why we parse strings ourselves.
        return format(typeof date === "string" ? parseDateISO8601(date) : date, formatStr, { locale });
    }, [locale]);
    const isTwelveHourFormat = (code) => code?.toLowerCase() === AM_PM_LOCALE;
    const flightTimeFormat = useCallback((timestamp, localeCode) => {
        const format = isTwelveHourFormat(localeCode) ? FLIGHT_TIME_AM_PM : FLIGHT_TIME;
        return dateFormat(timestamp, format);
    }, [dateFormat]);
    const flightDateFormat = useCallback((timestamp) => {
        return dateFormat(timestamp, FLIGHT_DATE);
    }, [dateFormat]);
    const flightTimeAndDateFormat = useCallback((timestamp, localeCode) => {
        const format = isTwelveHourFormat(localeCode) ? FLIGHT_TIME_AND_DATE_AM_PM : FLIGHT_TIME_AND_DATE;
        return dateFormat(timestamp, format);
    }, [dateFormat]);
    const flightDateAndTimeFormat = useCallback((timestamp, localeCode) => {
        const format = isTwelveHourFormat(localeCode) ? FLIGHT_DATE_AND_TIME_AM_PM : FLIGHT_DATE_AND_TIME;
        return dateFormat(timestamp, format);
    }, [dateFormat]);
    const flightDateAndTimeFormatWithWeekday = useCallback((timestamp, localeCode) => {
        const format = isTwelveHourFormat(localeCode)
            ? FLIGHT_DATE_AND_TIME_WITH_WEEKDAY_AM_PM
            : FLIGHT_DATE_AND_TIME_WITH_WEEKDAY;
        return dateFormat(timestamp, format);
    }, [dateFormat]);
    const flightDateFormatWithWeekday = useCallback((timestamp) => {
        return dateFormat(timestamp, FLIGHT_DATE_WITH_WEEKDAY);
    }, [dateFormat]);
    const birthDateFormat = useCallback((timestamp) => {
        return dateFormat(timestamp, BIRTH_DATE);
    }, [dateFormat]);
    const months = useMemo(() => {
        const today = Date.now();
        const firstDayOfYear = startOfYear(today);
        const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => dateFormat(addMonths(firstDayOfYear, month), "MMMM"));
        return months;
    }, [dateFormat]);
    const daysOfWeek = useMemo(() => {
        const today = Date.now();
        const firstDayOfWeek = startOfWeek(today);
        const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map((day) => dateFormat(addDays(firstDayOfWeek, day), "iiiiii"));
        return daysOfWeek;
    }, [dateFormat]);
    const flightSearchSummaryDateFormat = useCallback((timestamp) => {
        const dayOfWeek = new Date(timestamp).toString().substr(0, 3);
        const dateWithMonth = dateFormat(timestamp, FLIGHT_DATE_SEARCH_SUMMARY);
        const [dayOfMonth, shortMonthName] = dateWithMonth.split(" ");
        return { short_weekday: dayOfWeek, day_of_month: dayOfMonth, short_month_name: shortMonthName };
    }, [dateFormat]);
    return {
        dateFormat,
        daysOfWeek,
        months,
        flightTimeFormat,
        flightDateFormat,
        flightTimeAndDateFormat,
        flightDateAndTimeFormat,
        flightDateFormatWithWeekday,
        flightDateAndTimeFormatWithWeekday,
        birthDateFormat,
        flightSearchSummaryDateFormat
    };
}
export default useDateFormat;
